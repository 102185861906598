@import '_helpers';
@import '_variables';
@import 'mixins';

@import 'core/buttons/buttons';
@import 'components/latestGames/_latestGames.component';
@import 'components/fontAwesome-4.7/font-awesome';
@import 'components/notifications/_notifications.component';
@import 'components/perfectScrollbar/_perfectScrollbar.component';
@import 'components/tooltip/_tooltip.component';
@import 'components/ratings/_ratings.component';
@import 'components/staticPages/_staticPages.component';
@import 'components/owlCarousel/_owlCarousel.component';
@import 'components/jasperoConfirmations/jasperoConfirmations.component';
@import 'components/jasperoAlerts/jasperoAlerts.component';
@import 'components/alert/alert.component';
@import 'components/customUpload/_customUpload.component';
@import 'components/animatecss/_animatecss.component';
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

/* _________________________________________________________ */

* {
    box-sizing: border-box !important;
    outline: 0 !important;
}

.clearfix:after {
    content:" ";
    display:block;
    clear:both;
}

::selection {
    color: $primary-white;
    background: $selection-color;
}

::-moz-selection {
    color: $primary-white;
    background: $selection-color;
}

::-webkit-selection {
    color: $primary-white;
    background: $selection-color;
}

select,
input,
textarea {
    &.disabled,
    &.readonly,
    &[disabled],
    &[readonly] {
        cursor: not-allowed;
        opacity: 0.5;
    }
}

.flipX {
    transform: scaleX(-1);
    display: block;
}

/* _________________________________________________________ */

body {
    color: $primary-white;
    background: {
        color: $primary-black;
        image: url('#{$images-path}/background.jpg');
    };
    overflow: {
        x: auto;
        y: scroll;
    };
    .fullscreenMenu {
        display: none;
    }
    a:not([class]) {
        color: $primary-red;
        text-decoration: none;
        &:hover {
            color: $primary-white;
        }
    }
    .bonusContainer {
        margin: 10px 0 9px 0; // background-color: rgba(255,255,255,0.1);
        // 	padding: 10px;
        line-height: 17px;
        .progressBar {
            width: 100%;
            height: 18px;
            background-color: $progress-bar-background; //border-radius: 6px;

            .progressBar__item {
                display: block;
                position: relative;
                width: 0;
                height: 100%;
                transition: 0.4s linear;
                transition-property: width, background-color;
                background-color: $progress-bar-20-percent;
                &.progressBar__item--20percent {
                    background-color: $progress-bar-20-percent;
                }
                &.progressBar__item--40percent {
                    background-color: $progress-bar-40-percent;
                }
                &.progressBar__item--60percent {
                    background-color: $progress-bar-40-percent;
                }
                &.progressBar__item--80percent {
                    background-color: $progress-bar-80-percent;
                }
                &.progressBar__item--100percent {
                    background-color: $primary-green;
                }
            }
        }
        p {
            color: $primary-white;
            margin-top: 5px !important;
            text-align: center;
            font: {
                size: 13px;
                weight: 600;
            }
            ;
            .gray {
                color: $gray-color;
            }
        }
    }
    .stickyFooter {
        display: none;
        z-index: 1200;
        position: fixed;
        top: auto;
        left: auto;
        right: 0;
        bottom: 0;
        width: 100%;
        height: auto;
        background-color: $footer-background-color;
        letter-spacing: -0.31em;
        text-align: right;
        .stickyFooter__item {
            display: inline-block;
            width: 50%;
            min-width: 100px;
            letter-spacing: normal;
            padding: 5px;
            vertical-align: middle;
            a {
                @extend .truncate-text;
                width: 100%;
            }
        }
    }
    h1, h2, h3 {
        color: $primary-white;
        text-transform: uppercase;
        font-weight: 600;
        position: relative;
        &.bottomDecorator {
            &::after {
                display: block;
                height: 1px;
                width: 100%;
                content: '';
                bottom: -20px;
                background: {
                    image: url('#{$images-path}/horizontal-dot-separator.png');
                    repeat: repeat-x;
                }
                ;
                opacity: 0.2;
                position: absolute;
            }
        }
        span.subheader {
            display: block;
            font-size: 0.7em;
        }
    }
    h1, h2 {
        font-size: 24px;
        padding-top: 40px;
        margin: 0 0 40px 0;
        &.bottomDecorator {
            padding-top: 40px;
            padding-right: 20px;
            margin: 0 0 50px 0;
        }
        &.withoutTopPadding {
            padding-top: 0 !important;
        }
        &.big {
            font-size: 34px;
            padding-top: 50px;
            margin: 0 0 50px 0;
            font-weight: 700;
        }
        &.withoutStar {
            &::before {
                display: none !important;
            }
        }
    }
    h3 {
        font-size: 18px;
        margin: 30px 0;
        &.bottomDecorator {
            margin: 0 0 20px 0;
            &::after {
                bottom: -7px;
            }
        }
    }
    h1, h2 {
        &::before {
            display: block;
            width: 16px;
            height: 17px;
            content: '';
            right: 0;
            top: 48px;
            background: {
                image: url('#{$images-path}/star.png');
                repeat: no-repeat;
            }
            ;
            position: absolute;
        }
        .decorator {
            position: relative;
            display: inline-block;
            width: 39px;
            margin-right: 2px;
            height: 100%;
            background-color: $primary-red;
            text-align: center;
            &::after {
                content: "";
                position: absolute;
                right: 0;
                bottom: 0;
                width: 0;
                height: 0;
                border-right: 6px solid $primary-black;
                border-top: 16px solid transparent;
                border-bottom: 16px solid transparent;
            }
            .fa, [class^="icon-"] {
                transform: scale(0.6);
                text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
                margin-left: -5px;
                position: relative;
            }
            .header-custom-img {
                max-width: 20px;
                max-height: 20px;
                margin-left: -5px;
            }
            .all-games-icon {
                background: {
                    image: url('#{$images-path}/icons/all-games.png');
                    repeat: no-repeat;
                    position: center left;
                };
                margin: 0 5px 5px 0;
                width: 25px; height: 23px;
                display: inline-block;
                vertical-align: middle;
                position: relative;
                z-index: 2;
            }
        }
    }
    .form-element {
        position: relative !important;
    }
    .errorMessage {
        transition: all 0.25s ease-in-out;
        pointer-events: none;
        position: absolute;
        left: 0;
        right: 0;
        text-align: center;
        margin: 0 10px;
        bottom: -8px;
        overflow: hidden;
        @extend .truncate-text;
        .errorMessage__inside {
            transition: all 0.25s ease-in-out;
            @extend .truncate-text;
            background: {
                image: url('#{$images-path}/red-button-background.jpg');
                color: $primary-red;
            };
            margin: auto;
            color: $primary-white;
            padding: 2px 7px;
            font-size: 11px;
        }
        &.errorMessageBottomCorrection {
            bottom: -4px;
        }
        &.hidden {
            opacity: 0;
        }
    }

    .hints {
        font-size: 11px;
        color: $hints-color;
        margin: 2px 0;
    }

    input, textarea, select {
        transition: all 0.25s ease-in-out;
        &.ng-invalid:not(.ng-untouched) {
            border-color: $primary-red !important;
        }
        &:focus,
        &:focus.ng-invalid:not(.ng-untouched) {
            border-color: $select-border-color-focus !important;
            &+.errorMessage {
                opacity: 0;
            }
        }
    }

    input:not([type="checkbox"]):not([type="radio"]), textarea, select {
        border: 1px solid $select-border-color;
        padding: 9px 10px;
        font-size: 14px;
        font-family: $base-font-family;
        background-color: $select-background-color;
        color: $primary-white;
        &::-webkit-input-placeholder {
            color: $primary-grey-more-dark;
        }
        &::-moz-placeholder {
            color: $primary-grey-more-dark;
        }
        &:-ms-input-placeholder {
            color: $primary-grey-more-dark;
        }
        &:-moz-placeholder {
            color: $primary-grey-more-dark;
        }
        &.error {
            border-color: $primary-red;
        }
    }

    select {
        padding: 8px 10px;
    }

    label {
        position: relative !important;
        font: {
            weight: 700;
            size: 16px;
        }
        ;
        line-height: 16px;
        padding-bottom: 8px;
        display: block;
        &.acceptance {
            @extend .unselectable;
            font: {
                size: 11px;
                weight: 100;
            }
            ;
            text: {
                align: center;
            }
            ;
            line-height: 17px;
            color: $primary-grey-more-dark;
            padding-bottom: 12px;
            a {
                transition: all 0.25s ease-in-out;
                color: $primary-red;
                font-weight: 600;
                text-transform: initial;
                &:hover {
                    color: $primary-white;
                }
            }
        }
    }

    input[type="checkbox"], input[type="radio"], .akaCheckbox {
        width: auto;
        display: none;
    }

    .akaCheckbox {
        margin: auto;
        transition: all 0.25s ease-in-out;
        display: inline-block;
        width: 22px !important;
        height: 22px !important;
        background-color: $check-box-background-color;
        color: $check-box-background-color;
        line-height: 22px;
        text-align: center;
        border: 1px solid $select-border-color;
        cursor: pointer;
        padding: 0;
        position: relative;
        &::before {
            position: absolute;
            left: 0;
            top: 0;
            transition: inherit;
            content: "\f00c";
            font: {
                family: $base-font-family-font-awesome;
                size: 14px;
            }
            ;
            color: rgba(255,
            255,
            255,
            0);
            position: absolute;
            width: 20px;
            height: 20px;
            text-align: inherit;
            line-height: 20px;
        }
        &.error {
            border-color: $primary-red !important;
        }
        &:hover::before {
            color: rgba(255, 255, 255, 0.2);
        }
    }

    .inputWrapperRelative {
        position: relative;
        .acceptance {
            margin-left: 30px;
            width: auto !important;
        }
        .akaCheckbox {
            position: absolute !important;
            top: 4px;
            left: 0;
            display: inline-block;
        }
    }

    input[type="checkbox"]:checked+.akaCheckbox::before {
        color: $primary-green;
    }

    .bonusWidget__container {
        cursor: default;
        overflow: hidden;
        transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
        opacity: 0;
        transform: translate(0, -15px);
        display: block;
        width: 280px;
        background: {
            color: $primary-black;
            position: left top;
        }
        ;
        text-align: left;
        padding: 0;
        &>.bonusWidget__container-item {
            position: relative;
            padding: 0 0 41px 0;
            &::after {
                display: block;
                height: 1px;
                width: 100%;
                content: '';
                background: {
                    image: url('#{$images-path}/horizontal-dot-separator.png');
                    repeat: repeat-x;
                }
                ;
                opacity: 0.2;
                position: absolute;
                top: auto;
                bottom: 21px;
            }
            &:last-child,
            &.bonusWidget__container-item--last-child {
                padding-bottom: 0 !important;
                &::after {
                    display: none;
                }
            }
            .decorator {
                position: relative;
                display: block;
                background: {
                    image: url('#{$images-path}/crest-bonus-background.png');
                    repeat: repeat-x;
                }
                ;
                width: 70px;
                height: 72px;
                margin: 0 auto -10px auto;
                z-index: 2;
                .image {
                    position: absolute;
                    max-width: 100%;
                    border-radius: 90%;
                    overflow: hidden;
                    width: 48px;
                    height: 47px;
                    top: 11px;
                    left: 11px;
                    background: {
                        position: center;
                        repeat: no-repeat;
                        size: auto 119%;
                    }
                    ;
                }
                .fa,
                [class^="icon-"] {
                    width: 70px;
                    height: 72px;
                    line-height: 69px;
                    text-align: center;
                    font-size: 29px;
                    color: $footer-background-color;
                    text-shadow: -1px 1px 1px rgba(255, 255, 255, 0.55);
                    &.fa-eur {
                        position: relative;
                        margin-left: -2px;
                    }
                }
            }
            .button {
                white-space: normal !important;
                width: 100%;
                display: inline-block;
                line-height: 22px;
                padding: 11px 16px;
                margin-top: 0;
            }
        }
        p {
            margin: 0;
        }
    }
    .mainMenu {
        @extend .pure-menu-list;
        transition: padding 0.25s ease-in-out;
        padding-top: 0;
        &>.mainMenu__item {
            @extend .pure-menu-item;
            margin-bottom: 1px;
            .mainMenu__item-separator {
                width: calc(100% - 20px);
                height: 2px;
                background-image: url('#{$images-path}/menu-separator-background.png');
                margin: $main-menu-separator-padding auto;
                display: block;
            }
            &:first-child {
                transition: all 0.25s ease-in-out;
                margin-top: 0;
            }
            &.all-games {
                a {
                    //line-height: 30px;
                }
            }
            .mainMenu__item-anchor {
                @extend .unselectable;
                @extend .pure-menu-link; //transition: all 0.25s ease-in-out;
                transition: all 0.25s ease-in-out, background-color 0s;
                height: 53px;
                position: relative;
                background-color: transparent;
                font-size: 14px;
                font-weight: 600;
                line-height: 29px;
                color: $anchor-color;
                cursor: pointer;
                padding: 10px 25px 10px 23px; //10px 35px 10px 34px;
                text-overflow: clip !important;
                text-transform: uppercase;
                text-align: center;
                &::after {
                    transition: all 0.25s ease-in-out;
                    width: 0;
                    background: {
                        image: url('#{$images-path}/red-button-background.jpg');
                        color: $primary-red;
                    }
                    ;
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 100%;
                }
                &:focus {
                    background-color: transparent;
                }
                .mainMenu__custom-img {
                    width: 30px;
                    margin-right: 15px;
                    z-index: 2;
                    position: relative;
                }
                .all-games-icon {
                    background: {
                        image: url('#{$images-path}/icons/all-games.png');
                        repeat: no-repeat;
                        position: top left;
                    };
                    margin-right: 20px;
                    width: 25px; height: 23px;
                    display: inline-block;
                    vertical-align: middle;
                    position: relative;
                    z-index: 2;
                }

                &.mainMenu__item-anchor--active,
                &:active {
                    .all-games-icon {
                        background: {
                            position: bottom left;
                        };
                    }
                }

                &:hover {
                    .all-games-icon {
                        background: {
                            position: center left;
                        };
                    }
                }

                .fa,
                [class^="icon-"] {
                    transition: all 0.25s ease-in-out;
                    color: $anchor-color;
                    margin-right: 14px;
                    font-size: 20px;
                    top: 0;
                    position: relative;
                    margin-right: 0;
                    width: 100%;
                }
                [class^="icon-"] {
                    font-size: 30px;
                    //left: -4px;
                    //top: 10px;
                }
                span {
                    transition: opacity 0.25s ease-in-out;
                    opacity: 0;
                    color: $primary-white !important;
                    //line-height: 20px;
                    //top: 1px;
                    position: relative;
                    display: inline-block;
                    vertical-align: top;
                }
                span,
                .fa,
                [class^="icon-"] {
                    z-index: 2;
                    position: relative;
                }
                &:hover,
                &:active {
                    padding-left: 30px !important;
                    span,
                    .fa,
                    [class^="icon-"] {
                        color: $primary-white !important;
                    }
                    .fa {
                        font-size: 23px;
                    }
                    [class^="icon-"] {
                        font-size: 30px;
                        //top: 6px;
                        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
                    }
                    &::after {
                        width: 100%;
                    }
                }
                &:hover {
                    background-color: transparent !important;
                    span {
                        //top: 1px;
                        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
                    }
                    .fa {
                        top: 4px;
                        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
                    }
                }
                &:active {
                    background-color: $primary-white !important;
                    .fa,
                    [class^="icon-"] {
                        color: $primary-red !important;
                        text-shadow: none !important;
                    }
                    span {
                        color: $primary-pure-black !important;
                        text-shadow: none !important;
                    }
                    &::after {
                        width: 3px;
                    }
                }
                &.mainMenu__item-anchor--active {
                    color: $select-background-color;
                    padding: 10px 25px 10px 23px;
                    background: {
                        color: $primary-white;
                        repeat: no-repeat;
                        position: left top;
                    }
                    &::after {
                        width: 3px;
                    }
                    &::before {
                        background-color: $primary-black;
                    }
                    .fa,
                    [class^="icon-"] {
                        position: relative;
                        z-index: 2;
                        color: $primary-red;
                    }
                    span {
                        position: relative;
                        z-index: 2;
                        color: $select-background-color !important;
                        top: 1px;
                    }
                    &:hover {
                        img{
                            filter: none;
                        }
                        background-color: $primary-white !important;
                        &::after {
                            width: 100%;
                        }
                        span {
                            color: $primary-white !important;
                        }
                    }
                    &:active {
                        &::after {
                            width: 3px;
                        }
                        span {
                            color: $primary-pure-black !important;
                        }
                    }
                    img {
                        filter: brightness(0.3) sepia(1) hue-rotate(310deg) saturate(6);
                    }

                }
            }
        }
    }
    .panelMask {
        transition: opacity 0.65s ease-in-out;
        width: 0;
        height: 0;
        opacity: 0;
        position: fixed;
        z-index: 1206;
        display: block;
        background-color: rgba(0, 0, 0, 0.85);
        transform: translateZ(0);
        overflow: hidden;
        overflow-y: scroll;
        .closeLoginDropdownButton,
        .closeRegisterDropdownButton {
            cursor: pointer;
            transition: color 0.2s ease-in-out;
            display: inline-block;
            position: fixed;
            color: $button-main-color;
            left: auto;
            right: 0;
            top: 0;
            z-index: 2;
            font-size: 16px;
            padding: 10px 15px;
            &:hover {
                color: $primary-white;
            }
        }
    }

    // * Material Dialog
    .mat-dialog-container {
        @include rounded-corners-all(0px);
        @include shadow-component-all();
        background-color: $footer-background-color;
        color: $primary-white;
        padding: 0;
    }

    // * Material SnackBar
    .cdk-overlay-container {
        position: fixed;
        z-index: 10000;
        .mat-snack-bar-container {
            @include rounded-corners-all(0px);
            @include shadow-component-all();
        }
    }

    .error-snackbar {
        @include snackbar-type(darken($color: $primary-red, $amount: 10%));
    }

    .success-snackbar {
        @include snackbar-type($primary-green);
    }

    .info-snackbar {
        @include snackbar-type($primary-blue);
    }

    .warning-snackbar {
        @include snackbar-type(darken($color: $alert-warning-color, $amount: 25%), $primary-blue);
    }

    // jaspero-confirmation {
    //     z-index: 99999999 !important;

    // }

    // simple-notifications {
    //     z-index: 99999999;
    //     position: relative;
    //     .simple-notification-wrapper {
    //       z-index: 1204;
    //     }
    // }

    app-root {
        background: {
            color: inherit;
            image: inherit;
        }
        ;
        overflow: hidden;
    }

    .leftMenu,
    .footer {
        @extend .unselectable;
    }

    header {
        .headerContainer {
            @extend .columnContainer;
            height: $header-min-height;
            position: fixed !important;
            z-index: 1203;
            background: {
                color: $select-background-color;
                position: left top;
            }
            ;
        }
        .leftColumn {
            //position: relative;
            text-align: center;
            height: inherit;
        }
        .rightColumn {
            padding: 0 $column-padding-width;
            text-align: right;
            position: relative;
            &::before {
                position: absolute;
                display: block;
                width: 100%;
                height: 1px;
                left: 0;
                top: auto;
                bottom: 1px;
                content: '';
                opacity: 0.2;
                background: {
                    image: url('#{$images-path}/horizontal-dot-separator.png');
                    position: left top;
                    repeat: repeat-x;
                }
                ;
            }
            .headerMenu {
                //position: relative;
                display: inline-block;
                right: 0;
                left: auto;
                top: 0;
                width: auto;
                list-style: none;
                height: $header-search-height;
                padding: 0;
                margin: 0;
                letter-spacing: -10px;
                .headerMenu__item {
                    transition: all 0.25s ease-in-out;
                    display: inline-block;
                    vertical-align: top;
                    height: inherit;
                    position: relative;
                    letter-spacing: 0;
                    padding: 0 10px;
                    cursor: default;
                    border-bottom: 2px solid transparent;
                    &::before {
                        position: absolute;
                        display: block;
                        width: 1px;
                        height: 100%;
                        left: 0;
                        top: 0;
                        content: '';
                        opacity: 0.2;
                        background: {
                            image: url('#{$images-path}/vertical-dot-separator.png');
                            position: left top;
                            repeat: repeat-y;
                        }
                        ;
                    }
                    &:hover {
                        background-color: rgba(255, 255, 255, 0.1);
                    }
                    &.headerMenu__item--pointer {
                        cursor: pointer;
                    }
                    .headerMenu__item-additional {
                        position: fixed;
                        transform: translateZ(0);
                        z-index: 3;
                        left: 0;
                        right: 0;
                        top: $fixed-buttons-top-position;
                        width: auto;
                        height: auto;
                        &::before {
                            transition: opacity 0.25s ease-in-out;
                            left: 0;
                            top: -#{$fixed-buttons-top-position};
                            content: '';
                            display: block;
                            width: 0;
                            height: 0;
                            opacity: 0;
                            background-color: rgba(0, 0, 0, 0.5);
                            position: absolute;
                            z-index: 3;
                        }
                        &>.fixedButtons__item {
                            transition: all 0.25s ease-in-out;
                            width: 300px;
                            position: absolute;
                            right: -300px;
                            z-index: 1;
                            .fixedButtons__item--icon {
                                transition: inherit;
                                display: block;
                                margin-bottom: 2px;
                                width: 38px;
                                height: 38px;
                                background: {
                                    color: $primary-red;
                                    image: url('#{$images-path}/red-button-background.jpg');
                                }
                                ;
                                color: $primary-white;
                                line-height: 38px;
                                text-align: center;
                                font-size: 16px;
                                font-weight: 700;
                                cursor: pointer;
                                text-shadow: 0 1px 0 rgba(0,
                                0,
                                0,
                                0.5);
                                font-size: 24px;
                                left: -38px;
                                position: absolute;
                            }
                        }
                        &.fixedButtons--show {
                            &::before {
                                opacity: 1;
                                width: 100vw;
                                height: 100vh;
                            }
                        }
                    }
                    .headerMenu__item-bonus {
                        display: none;
                        width: 46px;
                        height: 100%;
                        padding-top: 44px;
                        font: {
                            size: 12px;
                            weight: 600;
                        }
                        ;
                        text: {
                            transform: uppercase;
                            align: center;
                        }
                        ;
                        color: $primary-white;
                        text-decoration: none !important;
                        font-weight: bold;
                        &.showBonus {
                            display: block;
                        }
                        &::after {
                            content: "\F06B";
                            position: absolute;
                            top: 7px;
                            left: 16px;
                            color: $header-second-accent;
                            font-size: 40px;
                            font-family: $base-font-family-font-awesome;
                            font-weight: lighter;
                        }
                        &::before {
                            transition: transform 0.6s ease-in-out;
                            display: none;
                            position: absolute;
                            top: 6px;
                            left: auto;
                            right: 7px;
                            content: '0';
                            background-color: $primary-red;
                            color: $primary-white;
                            text-align: center;
                            width: $header-counter-size;
                            height: $header-counter-size;
                            line-height: $header-counter-size;
                            border-radius: 90%;
                            text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
                            font-weight: 700;
                            overflow: hidden;
                            transform: scale(1);
                            opacity: 1;
                            z-index: 2;
                            font-weight: bold;
                            font-size: 12px;
                            padding: 1px;
                        }
                        &:not([data-counter=""]) {
                            &::before {
                                transform: scale(1);
                                opacity: 1;
                                display: block;
                                content: attr(data-counter);
                            }
                        }
                        &.loading {
                            &::before {
                                transform: scale(0) !important;
                                opacity: 0.33 !important;
                            }
                        }
                        &[data-counter="0"] {
                          &::before {
                            background-color: $header-background-gray;
                          }
                          &::after {
                            color: $header-background-after;
                          }
                        }
                    }
                    .bonusWidget__container-wrapper {
                        position: absolute;
                        right: -108px;
                        top: 68px;
                        padding-top: 0;
                        max-height: 0;
                        overflow: hidden;
                        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.4);
                        &::before {
                            transition: transform 0.05s ease-in-out, opacity 0.05s ease-in-out;
                            transform: translate(0, -15px);
                            opacity: 0;
                            transition: inherit;
                            position: absolute;
                            display: block;
                            content: '';
                            width: 0;
                            height: 0;
                            opacity: 1;
                            border: {
                                left: 4px solid transparent;
                                right: 4px solid transparent;
                                bottom: 4px solid $primary-black;
                            }
                            ;
                            top: 2px;
                            right: 138px;
                            left: auto;
                        }
                        .bonusWidget__container {

                            .bonusWidget__container-item {
                                height: 125px;
                                &.bonusWidget__container-item--last-child {
                                    height: auto;
                                    padding-bottom: 20px;
                                    .bonusWidget__graphic {
                                        height: auto;
                                    }
                                }
                                .bonusWidget__graphic {
                                    float: left;
                                    width: 120px;
                                    height: 120px;
                                    text-align: center;
                                    .bonusWidget__game-img {
                                        height: 80px;
                                    }
                                    .bonusWidget__icon {
                                        display: block;
                                        width: 74px;
                                        height: 74px;
                                        background-image: url('#{$images-path}/bonus-icon-bg.png');
                                        margin: 0 auto;
                                        .fa {
                                            font-size: 30px;
                                            line-height: 74px;
                                            color: $gray-color-1;
                                        }
                                    }
                                }
                                .button{
                                    width: calc(100% - 140px);
                                    background-color: lighten($color: $button-background-orange, $amount: 5%);
                                    padding: 11px;
                                    margin-top: 0;
                                    &:hover {
                                        background-color: $button-background-orange;
                                    }

                                }
                                .bonusWidget__text{
                                    width: calc(100% - 140px);
                                    color: $gray-color;
                                    font-size: 12px;
                                    font-weight: bold;
                                    margin-left: 120px;
                                    text-align: center;
                                    margin-top: 5px;
                                }
                            }
                        }
                    }
                    .userPanel__container-wrapper {
                        position: absolute;
                        right: -68px;
                        top: 68px;
                        padding-top: 0;
                        max-height: 0;
                        overflow: hidden;
                        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.4);
                        &::before {
                            transition: transform 0.05s ease-in-out, opacity 0.05s ease-in-out;
                            transform: translate(0, -15px);
                            opacity: 0;
                            transition: inherit;
                            position: absolute;
                            display: block;
                            content: '';
                            width: 0;
                            height: 0;
                            opacity: 1;
                            border: {
                                left: 4px solid transparent;
                                right: 4px solid transparent;
                                bottom: 4px solid $primary-black;
                            }
                            ;
                            top: 2px;
                            right: 141px;
                            left: auto;
                        }
                    }
                    &.languageSwitcherPanel {
                        .languageSwitcher__dropdown {
                            transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
                            opacity: 0;
                            transform: translate(0, -15px);
                            &.isActive {
                                transition: transform 0.33s ease-in-out, opacity 0.33s ease-in-out;
                                transform: translate(0, 0);
                                height: auto;
                                opacity: 1;
                                width: auto;
                            }
                        }
                    }
                    &.userPanel {
                        .userPanel__container-wrapper {
                            &.active {
                                max-height: none;
                                padding-top: 6px;
                                &::before {
                                    transition: opacity 0.33s ease-in-out;
                                    transform: translate(0, 0);
                                    opacity: 1;
                                }
                                .userPanel__container {
                                    transition: transform 0.33s ease-in-out, opacity 0.33s ease-in-out;
                                    opacity: 1;
                                    transform: translate(0, 0);
                                    padding: 15px 25px 15px 25px;
                                }
                            }
                        }
                    }
                    &.bonusWidget {
                        .bonusWidget__container-wrapper {
                            &.active {
                                box-shadow: 0 5px 15px rgba(0, 0, 0, 0.4);
                                max-height: calc(100vh - 78px);
                                padding-top: 6px;
                                &::before {
                                    transition: opacity 0.33s ease-in-out;
                                    transform: translate(0, 0);
                                    opacity: 1;
                                }
                                .bonusWidget__container {
                                    max-height: calc(100vh - 84px);
                                    transition: transform 0.33s ease-in-out, opacity 0.33s ease-in-out;
                                    opacity: 1;
                                    transform: translate(0, 0);
                                    padding: 15px;
                                    width: 425px;
                                    background-color: $page-background-color;
                                }
                            }
                        }
                    }
                    .headerMenu__item-register,
                    .headerMenu__item-login,
                    .headerMenu__item-makeDeposit,
                    .headerMenu__item-myBalance {
                        @extend .button;
                        font-size: 16px;
                        padding: 7px 14px 9px 14px !important;
                        margin-top: $header-element-margin-top !important;
                        line-height: normal !important;
                        .fa,
                        [class^="icon-"] {
                            margin-right: 8px;
                            font-size: 1.1em;
                        }
                    }
                    &.headerMenu__item--responsible {
                        img {
                            height: 85%;
                            padding-top: 15%;
                        }
                    }
                    .headerMenu__item-myBalance {
                        @extend .button--grey;
                        background-color: $header-background-gray-alt;
                        color: $primary-white;
                        width: auto;
                        display: inline-block;
                        padding: {
                            top: 5px !important;
                            right: 10px !important;
                            bottom: 4px !important;
                            left: 10px !important;
                        }
                        ;
                        .headerMenu__item-myBalance--label,
                        .headerMenu__item-myBalance--value {
                            text: {
                                transform: uppercase;
                                align: center;
                            }
                            ;
                            width: 100%;
                            display: block;
                            letter-spacing: 0;
                        }
                        .headerMenu__item-myBalance--label {
                            font: {
                                size: 8px;
                                weight: 700;
                            }
                            ;
                            line-height: 8px;
                            margin-bottom: 2px;
                            color: $label-color-main;
                        }
                        .headerMenu__item-myBalance--value {
                            font: {
                                size: 19px;
                                weight: 700;
                            }
                            ;
                            line-height: 19px;
                            letter-spacing: -1px;
                            .sign {
                                padding-right: 4px;
                            }
                        }
                    }
                    .headerMenu__item-register {
                        background-image: url('#{$images-path}/green-button-background.jpg');
                    }
                    .headerMenu__item-login {
                        text-align: left !important;
                        background-color: transparent !important;
                        box-shadow: none !important;
                        background-image: none !important;
                        span {
                            @extend .truncate-text;
                            display: block;
                            padding-left: 39px;
                            max-width: 200px !important;
                        }
                        .fa,
                        [class^="icon-"] {
                            position: absolute;
                            font-size: 31px;
                            margin-top: -4px;
                        }
                        img.userPicture {
                            width: 31px;
                            height: 31px;
                            border-radius: 90%;
                            position: absolute;
                            top: 3px;
                        }
                        &.headerMenu__item-login--logged {
                            span {
                                font-size: 16px;
                                font-weight: 200;
                                &:after {
                                    display: inline-block;
                                    vertical-align: middle;
                                    height: 20px;
                                    line-height: 20px;
                                    font-size: 12px;
                                    content: '▾';
                                }
                            }
                        }
                    }
                    language-switcher {
                        display: inline-block;
                        height: 100%;
                    }
                }
            }
        }
    }

    main {
        @extend .columnContainer;
        min-height: calc(100vh - #{$header-min-height});
        padding-top: $header-min-height;
        .container {
            padding: 0 60px 40px 64px !important;
            border-bottom: 2px solid rgba(0, 0, 0, 0.15);
            margin-bottom: 0;
            position: relative;
            &.container--minimalHeight {
                min-height: 60vh;
            }
            &.container--topPadding {
                padding-top: 40px !important;
            }
            &.container--dark,
            &.container--darker {
                &::before {
                    position: absolute;
                    display: block;
                    background-color: rgba(0, 0, 0, 0.15);
                    content: '';
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                }
            }
            &.container--darker {
                &::before {
                    background-color: rgba(0, 0, 0, 0.45);
                }
            }
            &.container--withoutImage {
                background-color: $primary-black;
            }
            &.container--first {
                padding-top: 0 !important; //30px !important;
            }
            &.container--last {
                padding-top: 60px !important;
            }
            &.container--noVerticalPadding {
                padding-top: 0 !important;
                padding-bottom: 0 !important;
            }
            .columnContainer {
                .columnContainer__column {
                    @extend .column;
                    width: 100%;
                    &.columnContainer__column--left {
                        width: calc(100% - 360px);
                        padding-right: 20px;
                    }
                    &.columnContainer__column--right {
                        width: 360px;
                    }
                    section {
                        margin-bottom: 27px;
                    }
                    p {
                        font-size: inherit;
                        line-height: inherit;
                        margin: 26px 0 26px 0;
                        font-size: 14px;
                        line-height: 24px;
                    }
                }
            }
        }
        .hamburger {
            transition: all 0.25s ease-in-out;
            @extend .fa,
            .fa-bars;
            position: fixed;
            top: 70px;
            left: 5px;
            color: $primary-white;
            font-size: 20px;
            cursor: pointer;
            z-index: 1205;
            width: 30px;
            height: 30px;
            text-align: center;
            line-height: 30px;
            padding: 34px;
            &:hover,
            &:active {
                color: $primary-grey;
            }
            &::before {
                position: relative;
                right: 8px;
                bottom: 16px;
            }
        }

        .leftColumn {
            z-index: 1204;
            background: {
                image: none;
                color: $select-background-color;
                position: left top;
            }
            ;
            box-shadow: 4px 0 0 rgba(0,
            0,
            0,
            0.5);
            overflow: hidden;
            top: -230px;
            height: calc(100vh + 230px);
            position: fixed;
            .logo {
                width: 260px;
                height: 228px;
                background: {
                    image: url('#{$images-path}/logo-background.png');
                    position: center;
                    repeat: no-repeat;
                }
                ;
                .logo__element {
                    transition: all 0.25s ease-in-out;
                    display: block;
                    width: 100%;
                    height: inherit;
                    background: {
                        image: url('#{$images-path}/logo.png');
                        position: 63px 42px;
                        repeat: no-repeat;
                        //size: 65%;
                    }
                    ;
                    &:hover {
                        opacity: 0.75;
                    }
                }
            }
            .mainMenuWrapper {
                transition: top 0.25s ease-in-out, height 0.25s ease-in-out;
                top: 140px;
                height: calc(100vh - 140px);
            }
        }
        .rightColumn {
            transition: padding 0.25s ease-in-out;
            width: 100% !important;
            padding-left: 80px !important;
            table {
                font-size: 14px;
                td,
                th {
                    padding: 10px;
                }
                thead {
                    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
                    tr {
                        &.bottomBorder {
                            border-bottom: 2px solid $gray-color-2;
                        }
                    }
                }
                tbody {
                    a {
                        color: $primary-red;
                        text-decoration: none;
                        font-weight: 700;
                        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
                        &:hover {
                            color: $primary-white;
                        }
                    }
                    &>tr {
                        &:nth-child(even) {
                            background-color: rgba(255, 255, 255, 0.05);
                        }
                        &:nth-child(odd) {
                            background-color: rgba(255, 255, 255, 0.08);
                        }
                        &.bottomBorder {
                            border-bottom: 1px solid $gray-color-2;
                        }
                    }
                }
                &.not-striped {
                    thead {
                        tr {}
                    }
                    tbody {
                        color: $primary-grey-more-dark;
                        strong,
                        b {
                            color: $primary-white;
                        }
                        tr {}
                    }
                    tr {
                        &:nth-child(even),
                        &:nth-child(odd) {
                            background-color: transparent;
                        }
                    }
                }
            }
            .sliderBlock {
                owl-carousel {
                    owl-carousel-child {
                        text-align: center !important;
                        margin-bottom: -30px !important;
                    }
                }
            }
        }
        .gameList {
            @extend .pure-g;
            //overflow: hidden;
            //position: relative;
            .gameList__item {
                &.gameList__item--no-data {
                    width: 100%;
                    padding: 0;
                    font-size: 14px;
                    color: $primary-red;
                    letter-spacing: normal;
                }
            }
            &.gameList__livecasino {
                width: 100%;
                padding: 1px;
                display: block;
                > * {
                    margin-top: 2%;
                    &:first-child {
                        margin-top: 0;
                    }
                    &.bottom-games {
                        > * {
                            &:not(:nth-child(3n+1)) {
                                margin-left: 2%;
                            }
                            &:nth-child(n + 4) {
                                margin-top: 2%;
                            }
                        }
                    }
                }
                .liveCasino__wrapper {
                    width: 32% !important;
                    .liveCasino__item {
                        .gameList__box {
                            .gameItem-button-wrapper {
                                position: absolute;
                                width: 100%;
                                display: flex;
                                justify-content: center;
                                .gameItem-button {
                                    padding: 10px 30px !important;
                                    text-transform: capitalize;
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                    &.top,
                    &.third {
                        width: 100% !important;
                        .liveCasino__item {
                            .gameList__box {
                                .gameList__vendor_wrapper {
                                    position: absolute;
                                    top: 20%;
                                    left: 0;
                                    bottom: auto;
                                    right: auto;
                                    display: flex;
                                    justify-content: center;
                                    width: 100%;
                                    .gameList__vendor_bg {
                                        background-color: rgba(0, 0, 0, 0.5);
                                        display: flex;
                                        .gameList__vendor {
                                            filter: brightness(0) invert(1);
                                        }
                                    }
                                }
                                .gameItem-button-wrapper {
                                    bottom: 15%;
                                }
                            }
                        }
                    }
                    &.third {
                      width: 49% !important;
                    }
                    &.second {
                        &.even {
                            margin-left: 2%;
                        }
                        .liveCasino__item {
                            .gameList__box {
                                .gameItem-button-wrapper {
                                    bottom: calc(50% - 20px);
                                }
                                .gameList__vendor_wrapper {
                                    top: 0;
                                    bottom: auto;
                                    right: auto;
                                    left: 0;
                                    .gameList__vendor {
                                        filter: brightness(0) invert(1);
                                    }
                                }
                            }
                        }
                    }
                    &.fourth {
                        .liveCasino__item {
                            .gameList__box {
                                .gameList__boxLive {
                                    display: none;
                                }
                                .gameList__vendor_wrapper {
                                    top: 0;
                                    right: auto;
                                    justify-content: left;
                                    width: auto;
                                    .gameList__vendor {
                                        filter: brightness(0) invert(1);
                                    }
                                }
                                .gameItem-button-wrapper {
                                    bottom: 10px;
                                }
                            }
                        }
                    }
                    &.bottom {
                        .liveCasino__item {
                            .gameList__box {
                                .gameList__boxLive {
                                    right: auto;
                                    display: flex;
                                    flex-direction: row-reverse;
                                    .extra-live-text {
                                        font-style: italic;
                                        font-size: 16px;
                                        padding: 2px 5px 1px;
                                    }
                                }
                                .gameList__vendor_wrapper {
                                    top: auto;
                                    bottom: 0;
                                    justify-content: flex-end;
                                    .gameList__vendor {
                                        filter: brightness(0) invert(1);
                                    }
                                }
                                .gameItem-button-wrapper {
                                    bottom: calc(50% - 20px);
                                }
                            }
                        }
                    }
                    &.fourth,
                    &.bottom {
                        width: 32% !important;
                    }

                   &.liveCasino__wrapper_one {
                    width: 100% !important;
                   }
                   &.liveCasino__wrapper_three {
                    width: 31.33% !important;
                    float: left;
                    &:nth-child(even) {
                        margin-left: 0;
                    }
                    &:nth-child(2) {
                     margin-left: 0;
                    }
                    &:nth-child(3) {
                     margin-left: 3%;
                     margin-right: 3%;
                    }
                    &:nth-child(6) {
                     margin-left: 3%;
                     margin-right: 3%;
                    }
                    .live-casino-buttons {
                        position: absolute;
                        top: 40%;
                        left: calc(50% - 58px);
                        visibility: hidden;
                        opacity: 0;
                        transition: all .3s ease-in-out;
                        transform: translate(-50%,-50%);


                    }
                    &:hover {
                        .live-casino-buttons {
                                top: 50%;
                                visibility: visible;
                                opacity: 1;
                        }
                    }
                   }
                   &:not(.liveCasino__wrapper_three) {
                        .button.button--red {
                            position: absolute;
                            top: 30%;
                            left: calc(50% - 48px) !important;
                            bottom: auto !important;
                            visibility: hidden;
                            opacity: 0;
                            transition: all .3s ease-in-out  !important;
                        }
                        &:hover {
                            .button.button--red {
                                top: 44%;
                                visibility: visible;
                                opacity: 1;
                                &:hover {
                                    transform: scale(1.1) !important;
                                }
                            }
                        }
                   }
                    .liveCasino__item {
                        display: block;
                        width: 100%;
                        letter-spacing: normal;
                        .gameList__box {
                            position: relative;
                            outline: 1px solid $gray-color-3 !important;
                            width: 100%;
                            .gameList__title_wrapper {
                                position: absolute;
                                top: 40%;
                                left: 0;
                                bottom: auto;
                                right: auto;
                                width: 100%;
                                display: flex;
                                justify-content: center;
                                h2 {
                                    padding: 15px;
                                    margin: 0;
                                    background-color: rgba(0, 0, 0, 0.5);
                                    color: #73db57;
                                    font-size: 34px;
                                    text-align: center;
                                    &:before {
                                        display: none;
                                    }
                                }
                            }
                            .gameList__vendor_wrapper {
                                position: absolute;
                                bottom: 0;
                                right: 0;
                                .gameList__vendor_bg {
                                    background-color: rgba(0, 0, 0, 0.5);
									display: flex;
                                }
                            }
                            .gameList__boxImg {
                                display: block;
                                width: 100%;
                            }
                            .gameList__boxLive {
                                position: absolute;
                                top: 0;
                                right: 0;
                                padding: 7px 10px;
                                background-color: rgba(0, 0, 0, 0.6);
                                margin: 0;
                                font-weight: bold;
                                font-style: italic;
                                text-transform: capitalize;
                                font-size: 18px;
                                span {
                                    background-color: $live-indicator-color;
                                    color: $primary-pure-black;
                                    border-radius:2px;
                                    padding: 0 5px;
                                    display: inline-block;
                                    text-transform: none;
                                    &.extra-live-text {
                                        color: white;
                                        background: transparent;
                                        font-style: normal;
                                        font-weight: 400;
                                    }
                                }
                            }
                            .button {
                                position: absolute;
                                bottom: 10px;
                                left: 10px;
                                box-shadow: 0 3px 0 rgba(0, 0, 0, 0.6);
                                transition: box-shadow 0.15s;
                                -webkit-transform: perspective(1px) translateZ(0);
                                transform: perspective(1px) translateZ(0);
                                box-shadow: 0 0 1px rgba(0, 0, 0, 0);
                                -webkit-transition-duration: 0.3s;
                                transition-duration: 0.3s;
                                -webkit-transition-property: box-shadow, transform;
                                transition-property: box-shadow, transform;
                                &:hover {
                                    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.8);
                                    -webkit-transform: scale(1.1);
                                    transform: scale(1.1);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &.mainMenu--show:not(.mainMenuUserInteraction--hide) {
        .leftColumn {
            width: $left-column-width;
        }
        .rightColumn {
            width: calc(100% - #{$left-column-width});
        }
        .smallLogo {
            opacity: 0;
            transition: opacity 0s;
        }
        main {
            .hamburger {
                top: 12px;
                color: $primary-white;
                font-size: 28px;
                &:hover {
                    color: $primary-grey;
                }
            }
            .leftColumn {
                top: 0;
                height: 100vh !important;
                .logo {
                    transition: all 0.25s ease-in-out;
                }
                .mainMenuWrapper {
                    top: 0;
                    height: calc(100vh - 238px);
                    .mainMenu {
                        padding-top: 0;
                        &>.mainMenu__item {
                            &:first-child {
                                margin-top: 18px;
                            }
                            .mainMenu__item-anchor {
                                @extend .truncate-text;
                                padding: 10px 35px 10px 34px;
                                text-align: left;
                                .fa,
                                [class^="icon-"] {
                                    width: 30px;
                                    margin-right: 15px;
                                }
                                span {
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
            }
            .rightColumn {
                padding-left: 260px !important;
            }
        }
        .game-sidebar-visible {
            width: calc(100% - #{$left-column-width});
            left: #{$left-column-width};
        }
    }
    &.loginPanel--show,
    &.registerPanel--show,
    &.webauthnPanel--show,
    &.tacModal--show,
    &.webauthnSuggessModalPanel--show {
        overflow-y: hidden;
        .panelMask {
            opacity: 1;
            width: 100%;
            height: 100vh;
        }
    }
    &.mobileMenuLayer--show {
        overflow-y: hidden;
    }
    .footerCopyright {
        @extend .columnContainer;
        .footerCopyright__item {
            @extend .column;
            width: auto;
            &.footerCopyright__item--left,
            &.footerCopyright__item--right {
                width: 300px;
                position: relative;
                &::before {
                    position: absolute;
                    display: block;
                    content: '';
                    width: 1px;
                    opacity: 0.2;
                    height: 100%;
                    background: {
                        image: url('#{$images-path}/vertical-dot-separator.png');
                        repeat: repeat-y;
                        position: left top;
                    }
                }
            }
            a {
                transition: all 0.25s ease-in-out;
                color: $primary-white;
                opacity: 0.5;
                text-decoration: none;
                &:hover {
                    opacity: 1;
                }
            }
            &.footerCopyright__item--left {
                padding-right: 80px;
                &::before {
                    left: 260px;
                }
            }
            &.footerCopyright__item--center {
                width: calc(100% - 600px);
            }
            &.footerCopyright__item--right {
                padding-left: 80px;
                &::before {
                    left: 40px;
                }
            }
            .footerLogos {
                padding-top: 20px;
                .footerLogos__item {
                    text-align: center;
                    margin-bottom: 20px;
                    img {
                        transition: all 0.25s ease-in-out;
                        max-width: 100%;
                        &:hover {
                            transform: scale(0.9);
                        }
                    }
                }
            }
            p {
                margin: 20px 0;
                font-size: 12px;
                line-height: 24px;
                color: rgba(255, 255, 255, 0.3);
                &.applicationVersion {
                    position: relative;
                }
            }
        }
    }
    &.goToTop--show {
        .goToTop {
            width: 70px;
            height: 70px;
            opacity: 1;
        }
    }
    // * Full screen mode
    .only-fullscreen {
        display: none;
    }

    &.fullscreen {
        overflow: hidden !important;

        .only-fullscreen {
            display: block;
            opacity: 1;
        }

        main .rightColumn,
        &.mainMenu--show:not(.mainMenuUserInteraction--hide) main .rightColumn {
            padding-left: 0 !important;
            .gameDetails,
            .gameContainer__rightColumn,
            .footer {
                display: none !important;
            }
        }
        header,
        .latestOpenedGames,
        .stickyFooter {
            display: none !important;
        }
        main {
            padding: 0 !important;
            .hamburger {
                display: none !important;
            }
            .leftColumn {
                display: none !important;
            }
        }
    }
}

ng-sidebar-container {
    height: 100vh !important;
}

.gameList__item--loading {
    height: 160px;
    width: 100%;
    min-height: 100px;
    padding: 0 10px 10px 10px;
    letter-spacing: normal;
    &::before {
        animation: rotation 0.65s infinite ease-out;
        content: '';
        width: 100px;
        height: 100px;
        display: block;
        background: {
            image: url('#{$images-path}/static-loader-min.png');
            position: center;
            repeat: no-repeat;
        }
        ;
        margin: auto;
    }
}

.app-web-api-unavailable-dialog {
    position: fixed;
    top: 20%;
    text-align: center;
    left: calc(50% - 200px);
    box-shadow: 0 5px 40px rgba(0, 0, 0, 0.6);
    transform-origin: bottom center;
    background: $primary-black!important;
    border-radius: 0!important;
    padding-bottom: 75px;
    width: auto!important;
    max-width: 400px;
    min-width: 300px;
    z-index: 1208!important;
    padding: 40px 20px 20px;

  &.app-web-api-unavailable-dialog--visible {
    display: block !important;
  }
  .unavailable-dialog__symbol {
  	svg {
  		    padding: 0;
    		margin-left: 35px;
        	width: 100px;
  	}
  }
  .unavailable-dialog__title {
  	span {
  		font-weight: bold;
  		font-size:1.3rem;
  	}
  }
  .app-web-api-unavailable-dialog-box {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $primary-white;
    margin: 12px 0 22px;
    .app-web-api-unavailable-dialog-message {
    	font-size: 0.9em;
    }
  }
  .unavailable-dialog__button {
  	border-radius: 0;
    background: rgba(141,150,165,.2);
    box-shadow: 0 3px 0 rgba(0,0,0,.6);
    padding: 10px 0;
    font-weight: bold;
    	a {
    		color: $primary-white;
    	}
  }
}

// * New Main Slider
body.mainMenu--show {
    .sliderBlock {
        .main-slider {
            .slider {
                overflow: hidden;
                max-height: 547px;
            }
        }
    }
}
body.mainMenu--show {
    .winnersSlider {
        .slider {
            overflow-y: hidden;
        }
    }
}

.p0{
    padding: 0px;
}