@import '../../../styles/variables';

.pure-menu {
    box-sizing: border-box
}

.pure-menu-fixed {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 3
}
.pure-menu-item,
.pure-menu-list {
    position: relative
}
.pure-menu-list {
    list-style: none;
    margin: 0;
    padding: 0
}
.pure-menu-item {
    padding: 0;
    margin: 0;
    height: 100%
}
.pure-menu-heading,
.pure-menu-link {
    display: block;
    text-decoration: none;
    white-space: nowrap
}
.pure-menu-horizontal {
    width: 100%;
    white-space: nowrap
}
.pure-menu-horizontal .pure-menu-list {
    display: inline-block
}
.pure-menu-horizontal .pure-menu-heading,
.pure-menu-horizontal .pure-menu-item,
.pure-menu-horizontal .pure-menu-separator {
    display: inline-block;
    zoom: 1;
    vertical-align: middle
}
.pure-menu-item .pure-menu-item {
    display: block
}
.pure-menu-children {
    display: none;
    position: absolute;
    left: 100%;
    top: 0;
    margin: 0;
    padding: 0;
    z-index: 3
}
.pure-menu-horizontal .pure-menu-children {
    left: 0;
    top: auto;
    width: inherit
}
.pure-menu-active>.pure-menu-children,
.pure-menu-allow-hover:hover>.pure-menu-children {
    display: block;
    position: absolute
}
.pure-menu-has-children>.pure-menu-link:after {
    padding-left: .5em;
    content: "\25B8";
    font-size: small
}
.pure-menu-horizontal .pure-menu-has-children>.pure-menu-link:after {
    content: "\25BE"
}
.pure-menu-scrollable {
    overflow-y: scroll;
    overflow-x: hidden
}
.pure-menu-scrollable .pure-menu-list {
    display: block
}
.pure-menu-horizontal.pure-menu-scrollable .pure-menu-list {
    display: inline-block
}
.pure-menu-horizontal.pure-menu-scrollable {
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: auto;
    -ms-overflow-style: none;
    -webkit-overflow-scrolling: touch;
    padding: .5em 0
}
.pure-menu-horizontal.pure-menu-scrollable::-webkit-scrollbar {
    display: none
}
.pure-menu-horizontal .pure-menu-children .pure-menu-separator,
.pure-menu-separator {
    background-color: $primary-grey;
    height: 1px;
    margin: .3em 0
}
.pure-menu-horizontal .pure-menu-separator {
    width: 1px;
    height: 1.3em;
    margin: 0 .3em
}
.pure-menu-horizontal .pure-menu-children .pure-menu-separator {
    display: block;
    width: auto
}
.pure-menu-heading {
    text-transform: uppercase;
    color: $gray-color-1;
}
.pure-menu-link {
    color: $gray-color;
}
.pure-menu-children {
    background-color: $primary-white;
}
.pure-menu-disabled,
.pure-menu-heading,
.pure-menu-link {
    padding: .5em 1em
}
.pure-menu-disabled {
    opacity: .5
}
.pure-menu-disabled .pure-menu-link:hover {
    background-color: transparent
}
.pure-menu-active>.pure-menu-link,
.pure-menu-link:focus,
.pure-menu-link:hover {
    background-color: $primary-grey;
}
.pure-menu-selected .pure-menu-link,
.pure-menu-selected .pure-menu-link:visited {
    color: $primary-black;
}