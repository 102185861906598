@import '../../_variables';
@import '../../_helpers';
@import '../../core/grids/grids';

.custom-upload {
	@extend .pure-g;
	height: 39px;
	width: 100%;
	line-height: 39px;
	position: relative;
	cursor: pointer;
	overflow: hidden;
	padding: 0;

	.custom-upload__icon {
	    line-height: inherit;
	    color: inherit;
	    padding-right: 5px;
	}

	.custom-upload__browseButton,
	.custom-upload__text {
		@extend .pure-u-1-1;
		transition: all 0.2s ease-in-out;
		width: auto !important;
	}

	.custom-upload__browseButton {
		@extend .button, .button--grey;
		padding: {
			top: 6px !important;
			bottom: 5px !important;
		};
	}

	.custom-upload__text {
		height: inherit;
		background-color: $primary-black;
		color: $gray-color-1;
		width: 250px;
		position: relative;
		height: inherit;
		margin-left: 10px;

		&::after {
			content: attr(data-path);
			width: 210px;
			height: inherit;
			line-height: inherit;
			color: $gray-color;
			font-size: 14px;
			padding: {
				left: 10px;
				right: 10px;
			};
			box-sizing: border-box;
			display: inline-block;
		    white-space: nowrap;
		    overflow:hidden !important;
		    text-overflow: ellipsis;
		}
	}

	& > input {
		display: none;
	}

	.custom-upload-preview {
		position: absolute;
		z-index: 2;
		top: 0;
		left: auto;
		right: 0;
		width: 42px;
		height: 42px;
		padding: 1px;
		box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);

		& > img {
			width: 42px;
			height: 42px;
			object-fit: cover;
		}
	}

	&:hover {
		.custom-upload__text {
			color: $primary-white;
		}
	}
}
