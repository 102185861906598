.ps-container {

	&.ps-active-x > .ps-scrollbar-x-rail,
	&.ps-active-y > .ps-scrollbar-y-rail {
		border-radius: 6px !important;
	}

	& > .ps-scrollbar-y-rail,
	& > .ps-scrollbar-x-rail {
		& > .ps-scrollbar-y,
		& > .ps-scrollbar-x {
			background-color: rgba(255, 255, 255, 0.57) !important;
			box-shadow: 0 0 10px rgba(0, 0, 0, 0.45) !important;
			//background-color: rgba(202, 10, 33, 0.57) !important;//#ffffff !important;
		}
	}

	&:hover .ps-scrollbar-y-rail:hover,
	&:hover.ps-in-scrolling .ps-scrollbar-y-rail,
	&.ps-in-scrolling .ps-scrollbar-y-rail {
		background-color: rgba(125, 125, 125, 0.15) !important;
		opacity: 0.9 !important;
	}
}