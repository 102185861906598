@import "fonts/_opensans-font-local";
@import "fonts/_wicked-mouse-local";
@import "fonts/_elcarado-icons-local";
@import "fonts/_lemon-milk-local";
@import "fonts/_lemon-milk-bold-local";
@import "fonts/_lemon-milk-light-local";
@import "fonts/_exo2-bold-local.scss";
@import "fonts/_roboto-local.scss";
@import "fonts/_material-icon-local.scss";

@import "core/buttons/buttons";
@import "core/menu/menu";
@import "core/grids/grids";
@import "core/forms/forms";
@import "core/tables/tables";
@import "_helpers";
@import "_variables";
@import "_keyframes";

* {
  outline: 0 !important;
  box-sizing: border-box !important;
}

body {
  font-family: "Open Sans", Verdana, Arial, sans-serif;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  background-color: $background-color;

  .smallButton {
    transition: all 0.275s ease-in-out;
    padding: 5px 10px;
    background-color: $page-background-color;
    text-align: center;
    width: 100%;
    margin-bottom: 8px;
    box-shadow: 3px 3px 0 rgba(0, 0, 0, 0.5);
    color: $primary-white !important;
    &:hover {
      opacity: 0.75;
    }

    &.smallButton--half {
      width: calc(50% - 5px) !important;
      display: inline-block !important;
    }

    &.smallButton--half-left {
      margin-right: 5px !important;
    }

    &.smallButton--half-right {
      margin-left: 5px !important;
    }
  }

  .loaderContainer {
    transition: opacity 0.65s ease-in-out;
    width: 100%;
    height: 100vh;
    opacity: 1;
    position: fixed;
    z-index: 1999;
    display: block;
    background-color: rgba(0, 0, 0, 0.5);
    transform: translateZ(0);
    overflow: hidden;
    text-align: center;

    &::before {
      position: absolute;
      content: attr(data-text);
      width: inherit;
      height: 100%;
      display: block;
      text-align: center;
      padding-top: calc(50vh + 38px);
      font-size: 10px;
      letter-spacing: 2px;
      text-transform: uppercase;
    }

    .loader {
      transform: inherit;
      animation: rotation 0.65s infinite ease-out;
      width: 82px;
      height: 82px;
      display: block;
      background: {
        image: url("#{$images-path}/static-loader-min.png");
        position: center;
        repeat: no-repeat;
      }
      top: calc(50vh - 41px);
      position: relative;
      margin: auto;
    }
  }

  app-root {
    display: block;
    min-height: inherit;
    background-color: inherit;
    .loadingText {
      width: 100%;
      text-align: center;

      .loadingText__inside {
        width: auto;
        padding: 10px;
        border-radius: 9px;
        top: calc(50vh - 15px);
        font-size: 12px;
        font-weight: 700;
        color: $primary-white;
        position: relative;
      }
    }
  }

  .container--first {
    .payments {
      .paymentsSlider {
        .slider.platform-server {
          height: 60px;
          overflow: hidden;
          .slide {
            .clone {
              display: none;
            }
          }
        }
      }
    }
    .vendors {
      .paymentsSlider {
        .slider.platform-server {
          height: 90px;
          overflow: hidden;
          .slide {
            .clone {
              display: none;
            }
          }
        }
      }
    }
  }
  .logosContainer {
    .logosSlider {
      .slider {
        overflow: hidden !important;
        max-height: 65px;
        &.platform-server {
          height: 65px;
          .slide {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            left: 0;
            position: relative;
            .logosSlider__item {
              width: auto !important;
              flex: 1 0 14.285713%;
              &.clone {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  &.page--loaded {
    app-root {
      text-align: left;
    }

    .loaderContainer {
      opacity: 0;
      width: 0;
      height: 0;
      .loader {
        animation-duration: 0s;
      }
    }
  }
}

header,
footer {
  @extend .unselectable;
}
