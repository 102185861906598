@charset "UTF-8";
@import '../_variables';


@font-face {
  font-family: 'icomoon';
  src:  url('#{$font-path}/ElcaradoIcons2/icomoon.eot?uddavd');
  src:  url('#{$font-path}/ElcaradoIcons2/icomoon.eot?uddavd#iefix') format('embedded-opentype'),
    url('#{$font-path}/ElcaradoIcons2/icomoon.ttf?uddavd') format('truetype'),
    url('#{$font-path}/ElcaradoIcons2/icomoon.woff?uddavd') format('woff'),
    url('#{$font-path}/ElcaradoIcons2/icomoon.svg?uddavd#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-icon1:before {
  content: "\e900";
}
.icon-icon2:before {
  content: "\e901";
}
.icon-icon3:before {
  content: "\e902";
}
.icon-icon4:before {
  content: "\e903";
}
.icon-icon5:before {
  content: "\e904";
}
.icon-icon6:before {
  content: "\e905";
}
.icon-icon7:before {
  content: "\e906";
}
.icon-icon8:before {
  content: "\e907";
}
.icon-icon9:before {
  content: "\e908";
}
.icon-icon10:before {
  content: "\e909";
}
.icon-icon11:before {
  content: "\e90a";
}
.icon-icon12:before {
  content: "\e90b";
}
.icon-icon13:before {
  content: "\e90c";
}
.icon-icon14:before {
  content: "\e90d";
}
.icon-icon15:before {
  content: "\e90e";
}
.icon-icon16:before {
  content: "\e90f";
}
.icon-icon17:before {
  content: "\e910";
}
.icon-icon18:before {
  content: "\e911";
}
.icon-icon19:before {
  content: "\e912";
}
.icon-icon20:before {
  content: "\e913";
}
.icon-icon21:before {
  content: "\e914";
}
.icon-icon22:before {
  content: "\e915";
}
.icon-icon23:before {
  content: "\e916";
}
.icon-icon24:before {
  content: "\e917";
}
.icon-icon25:before {
  content: "\e918";
}
.icon-icon26:before {
  content: "\e919";
}
.icon-icon27:before {
  content: "\e91a";
}
.icon-icon28:before {
  content: "\e91b";
}
.icon-icon29:before {
  content: "\e91c";
}