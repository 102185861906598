// @import './_variables';
@import './mixins';
@import './core/grids/grids';

.unselectable {
	user-select: none;
}
.selectable {
	user-select: text !important;
}

.strong {
	font-weight: bold;
}
.light {
	font-weight: lighter;
}
.text-to-right {
	text-align: right;
}
.text-to-left {
	text-align: left;
}
.left {
	float: left;
}
.right {
	float: right;
}
.hidden,
[hidden] {
	display: none !important;
}
.truncate-text {
	max-width: 100%;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}
.capitalize-text {
	text-transform: capitalize;
}

@mixin truncate-text() {
	max-width: 100%;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.button {
	@extend .pure-button !optional;
	border-radius: 4px;
	border: 0;
	margin: 0;
	position: relative;
	transition: all 0.25s ease-in-out;
	box-shadow: 0 3px 0 rgba(0, 0, 0, 0.6);
	background-color: $primary-green;
	color: $primary-white !important;
	display: inline-block;
	text-align: center;
	padding: 8px 16px; //12px 15px 18px 15px;
	font: {
		weight: 700;
	};
	text-shadow: 0 1px 1px rgba(0, 0, 0, 0.7);
	cursor: pointer;
	height: auto;
	line-height: 28px;

	&.button--red {
		// @extend .button-error !optional;
		@include mat-raised-button($primary-red, $primary-white);
	}

	&.button--blue {
		background: {
			color: $button-background-blue !important;
			image: url('#{$images-path}/blue-button-background.jpg') !important;
		}

	}
	&.button--green {
		background: {
			color: $primary-green !important;
			image: url('#{$images-path}/green-button-background.jpg') !important;
		};
	}

	&.button--gray,
	&.button--grey {
		background: {
			color: $button-main-border !important;
			image: url('#{$images-path}/gray-button-background.jpg') !important;
		};
	}

	&.button--small {
		@extend .button-small !optional;
	}

	&.button--disabled,
	&[disabled] {
	    opacity: 0.4 !important;
	    cursor: default !important;
	}
}

.greyBox {
	background-color: $footer-background-color;
	padding: 20px;
	box-shadow: 0 3px 0 rgba(0, 0, 0, 0.5);
}

.columnContainer {
	display: flex;
	width: 100%;
	flex-flow: row wrap;
	position: relative;
}

.container {
	padding: $column-padding-width;
}

.column {
	display: inline-block;
}

.leftColumn {
	display: inline-block;
	transition: width 0.25s ease-in-out, top 0.25s ease-in-out, height 0.25s ease-in-out;
	width: $left-column-min-width;
}

.rightColumn {
	display: inline-block;
	transition: width 0.25s ease-in-out;
	width: calc(100% - #{$left-column-min-width});
}

@keyframes fadein {
	0% { opacity:0; }
	66% { opacity:0; }
	100% { opacity:1; }
}

@-webkit-keyframes fadein {
	0% { opacity:0; }
	66% { opacity:0; }
	100% { opacity:1; }
}

.mb-15 {
  margin-bottom: 15px;
}

.mt-15 {
  margin-top: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mt-20 {
	margin-top: 20px;
}


.mb-40 {
  margin-bottom: 40px;
}

.mt-40 {
	margin-top: 40px;
}

.dropdown-attributes {
	@extend .unselectable;
	transform: translate(0, -220vh);
	text-align: left;
	display: block;
	transition: transform 0.75s ease-in-out, opacity 0.25s ease-in-out;
	position: absolute;
	height: 0;
	width: 0;
	opacity: 0;
	top: 94px;
	z-index: 1206;
	margin: 0 auto;
	left: 0;
	right: 0;
	width: 100%;
	.tac__dropdown-wrapper,
	.login__dropdown-wrapper{
		@extend .dropdown-wrapper;
	}
	h2 {
			font-weight: 700;
			text-align: center;
			padding-top: 0;
			margin: -5px 0 25px 0;
			&::before {
					background: {
							image: url('#{$images-path}/horizontal-dot-separator.png');
							repeat: repeat-x;
					}
					;
					opacity: 0.2;
					left: 0;
					top: 17px;
					width: 100%;
			}
			span {
					z-index: 2;
					position: relative;
					padding: 0 35px;
					background: {
							image: url('#{$images-path}/background.jpg');
					}
					;
			}
	}
	hr {
			margin: 0;
			border: {
					top: 1px solid transparent;
					right: 0;
					bottom: 0;
					left: 0;
			}
			;
			position: relative;
			&::before {
					content: '';
					position: absolute;
					background: {
							image: url('#{$images-path}/horizontal-dot-separator.png');
					}
					;
					opacity: 0.2;
					top: -1px;
					left: 0;
					width: 100%;
					height: 1px;
			}
			&::after {
					content: attr(data-text);
					position: absolute;
					display: inline-block;
					transform: translate(-50%, -50%);
					left: 50%;
					padding: 0 10px;
					font-size: 15px;
					color: $primary-grey-more-dark;
					margin-top: -2px;
					background: {
							image: url('#{$images-path}/background.jpg');
					}
					;
			}
	}
	.login__dropdown-inside,
	.tac__dropdown-inside,
	.webauthn_suggess_dropdown-inside {
		@extend .dropdown-inside;
	}
	&.active {
			height: auto;
			width: 440px;
			opacity: 1;
			transform: translate(0, 0);
	}
}

.dropdown-wrapper {
	width: 440px;
	margin: 0 auto 100px auto;
	position: relative;
	box-shadow: 0 0 30px rgba(0, 0, 0, 0.6);
	background: {
			color: $primary-black;
			image: url('#{$images-path}/background.jpg');
	};
	&::before {
			position: absolute;
			bottom: -105px;
			top: auto;
			transform: translate(-50%, -50%);
			left: 50%;
			display: block;
			content: '';
			width: 105px;
			height: 105px;
			background: {
					image: url('#{$images-path}/secure-100-percent.png');
					repeat: no-repeat;
			};
		z-index: 2;
	}
}

.dropdown-inside {
	padding: 30px 50px;
	display: table;
	width: 100%;
	position: relative;
	&>.login__dropdown-insideElement,
	&>.register__dropdown-insideElement,
	&>.webauthn__dropdown-insideElement,
	&>.webauthn_suggess__dropdown-insideElement {
		@extend .dropdown-inside-element;
	}
	.button {
			line-height: 23px !important;
			.fa,
			[class^="icon-"] {
					position: absolute;
					font-size: 22px;
					margin-left: -17px;
			}
			.fa.fa-angle-right {
					margin-left: 5px;
			}
			.textInside {
					padding-left: 16px;
			}
			&[class^="icon-"]RightPosition {
					.fa,
					[class^="icon-"] {
							margin-left: 10px;
					}
					.textInside {
							padding-left: 0;
					}
			}
	}
	&.withoutVerticalPadding {
			padding: {
					top: 0;
					bottom: 0;
			}
	}
	&.darkBackground {
			background-color: rgba(0, 0, 0, 0.3);
	}
	&.paddingForDecorator {
			padding-bottom: 83px;
	}
	&.first {
			padding-top: 40px;
	}
}

.dropdown-inside-element {
	margin-bottom: 10px;
	&.moreMarginBottom {
			margin-bottom: 20px;
	}
	&.centeredText {
			text-align: center;
	}
	&.quarterMarginBottom {
			margin-bottom: 2.5px;
	}
	&.withoutMargin {
			margin-bottom: 0 !important;
	}
	p {
			font-size: 14px;
			line-height: 21px;
			margin: 0 0 10px 0;
	}
	input,
	textarea,
	a {
			width: 100%;
	}
	.anchor {
			font-size: 14px;
			color: $primary-red;
			display: inline-block;
			line-height: 13px;
			cursor: pointer;
			width: auto;
			text-decoration: none;
			&:hover {
					color: $primary-white;
			}
	}
	h3 {
			margin: 0 0 30px 0;
			text: {
					align: center;
					transform: initial;
			}
			;
			font: {
					size: 16px;
					weight: 700;
			}
			;
	}
	&:last-child {
			margin-bottom: 0;
	}
}

.webauthn_suggess_dropdown-inside{
	display: block;
	.button.button--green,.button.button--red{
		width: 100%;
	}
}