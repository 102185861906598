.tooltip {
    max-width: 200px;
    background-color: rgba(0, 0, 0, 0.75);
    color: $primary-white;
    text-align: center;
    border-radius: 6px;
    padding: 10px 13px;
    position: absolute;
    z-index: 999999;
    display: block;
    opacity: 0;
    transition: opacity 0s;
    font-size: 12px;
    margin-left: 2px;
}

.tooltip-show {
    opacity: 1;
}

.tooltip::after {
    content: "";
    position: absolute;
    border-style: solid;
}

.tooltip-top::after {
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-color: rgba(0, 0, 0, 0.75) transparent transparent transparent;
}

.tooltip-bottom::after {
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-color: transparent transparent rgba(0, 0, 0, 0.75) transparent;
}

.tooltip-left::after {
    top: 50%;
    left: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-color: transparent transparent transparent rgba(0, 0, 0, 0.75);
}

.tooltip-right::after {
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-color: transparent rgba(0, 0, 0, 0.75) transparent transparent;
}
