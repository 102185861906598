@import '../../_variables';

.simple-notification-wrapper {
	.simple-notification {
		&.error {
			background: {
				color: $primary-red;
				image: url('#{$images-path}/red-button-background.jpg');
			};
			.sn-progress-loader {
				span {
					background-color: darken($color: $primary-red, $amount: 10%);
				}
			}
		}
		
		&.success {
			background: {
				color: $primary-green;
				image: url('#{$images-path}/green-button-background.jpg');
			};

			.sn-progress-loader {
				span {
					background-color: $primary-green;
				}
			}
		}
		
		&.alert {
			background-color: $alert-warning-color;
		}

		&.info {
			background-color: $alert-info-color;
		}

		.sn-content {
			max-height: 40vh;
			overflow: hidden;
			font-size: 11px;
			line-height: 15px;
			padding-right: 46px;
            a {
                color: $primary-white;
                text-decoration: underline;
            }
			margin: {
				top: 3px;
				bottom: 10px;
			};
		}
	}
}

/* Tablets (max 620px) ----------- */
@media all and (max-width: 620px) {
	.simple-notification-wrapper {
		.simple-notification {
			margin-bottom: 0;
			margin-top: 10px;
			padding: 5px 10px;
			.sn-title {
				font-size: 17px;
				line-height: 24px;
			}
			.sn-content {
				font-size: 10px;
				line-height: 13px;
			}
		}
	}
}
