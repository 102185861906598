@keyframes rotation {
    0% {
        transform: rotate(0deg) scale(0.4);
    }
    20% {
        transform: rotate(72deg) scale(0.6);
    }
    40% {
        transform: rotate(144deg) scale(0.8);
    }
    60% {
        transform: rotate(216deg) scale(1);
    }
    80% {
        transform: rotate(288deg) scale(0.8);
    }
    100%   {
        transform: rotate(360deg) scale(0.6);
    }
}