@import '../../../styles/_variables';

table {
    border-collapse: collapse;
    border-spacing: 0
}
td,
th {
    padding: 0
}

table,
th,
td {
    border: none
}
table {
    width: 100%;
    display: table
}
table.bordered>thead>tr,
table.bordered>tbody>tr {
    border-bottom: 1px solid $primary-grey;
}
table.striped>tbody>tr:nth-child(odd) {
    background-color: rgba(255, 255, 255, 0.05);
}
table.striped>tbody>tr>td {
    border-radius: 0
}
table.highlight>tbody>tr {
    transition: background-color .25s ease
}
table.highlight>tbody>tr:hover {
    background-color: rgba(255, 255, 255, 0.05);
}
table.centered thead tr th,
table.centered tbody tr td {
    text-align: center
}
thead {
    border-bottom: 1px solid $primary-grey;
}
td,
th {
    padding: 10px 5px;
    display: table-cell;
    text-align: left;
    vertical-align: middle;
    border-radius: 2px;

    &.to-right {
        text-align: right;
    }
}

th {
    padding: 10px 5px;
}

table {
    ul {
        list-style: none;
        padding: 0;

        li {
            display: inline-block;
            width: auto;
            margin-right: 10px;

            md-icon {
                cursor: pointer;

                &:hover,
                &:active {
                    opacity: 0.8;
                }

                &[data-action="delete"] {
                    color: $primary-red;
                }
            }
        }
    }
}

.table--loading {
    width: 100%;
    text-align: center;
    margin-top: 10px;
    font-size: 13px;
    height: 60px;
}

.table--no-data {
    box-sizing: border-box;
    width: 100%;
    text-align: center;
    padding: 10px;
    font-size: 13px;
    line-height: 16px;
    color: #b1b1b1;
}

.table-column-checkbox {
    width: 40px;
}

@media only screen and (max-width: 992px) {
    table.responsive-table {
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;
        display: block;
        position: relative
    }
    table.responsive-table td:empty:before {
        content: '\00a0'
    }
    table.responsive-table th,
    table.responsive-table td {
        margin: 0;
        vertical-align: top
    }
    table.responsive-table th {
        text-align: left
    }
    table.responsive-table thead {
        display: block;
        float: left
    }
    table.responsive-table thead tr {
        display: block;
        padding: 0 10px 0 0
    }
    table.responsive-table thead tr th::before {
        content: "\00a0"
    }
    table.responsive-table tbody {
        display: block;
        width: auto;
        position: relative;
        overflow-x: auto;
        white-space: nowrap
    }
    table.responsive-table tbody tr {
        display: inline-block;
        vertical-align: top
    }
    table.responsive-table th {
        display: block;
        text-align: right
    }
    table.responsive-table td {
        display: block;
        min-height: 1.25em;
        text-align: left
    }
    table.responsive-table tr {
        padding: 0 10px
    }
    table.responsive-table thead {
        border: 0;
        border-right: 1px solid $primary-grey;
    }
    table.responsive-table.bordered th {
        border-bottom: 0;
        border-left: 0
    }
    table.responsive-table.bordered td {
        border-left: 0;
        border-right: 0;
        border-bottom: 0
    }
    table.responsive-table.bordered tr {
        border: 0
    }
    table.responsive-table.bordered tbody tr {
        border-right: 1px solid $primary-grey;
    }
}