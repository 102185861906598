@import '../../_variables';
@import '../../_helpers';

.alert {
	display: block;
	width: 100%;
	padding: 17px;
	border: 4px solid transparent;
	min-height: 98px;

	&::before {
		display: block;
		position: absolute;
		width: 56px;
		height: 56px;
		line-height: 56px;
		text-align: center;
		background-color: transparent;
		content: ".";
		font-family: "FontAwesome";
		color: $primary-white;
		font-size: 28px;
	}

	.alert__header,
	.alert__paragraph {
		padding-left: 78px;
	}

	.alert__header {
		color: $primary-white;
		text-transform: uppercase;
		font: {
			weight: 700;
			size: 16px;
		};
		margin: 0 0 5px 0;
		line-height: 16px;
	}

	.alert__paragraph {
		color: $primary-grey-more-dark;
		margin: 0;
		font-size: 14px;
		line-height: 17px;
	}

	&.alert--information {
		border-color: $primary-blue-other;

		&::before {
			background-color: $primary-blue-other;
			content: "\f05a";
		}
	}

	&.alert--success {
		border-color: $primary-green;

		&::before {
			background-color: $primary-green;
			content: "\f00c";
		}
	}

	&.alert--error {
		border-color: $primary-red;

		&::before {
			background-color: $primary-red;
			content: "\f05c";
		}
	}
}