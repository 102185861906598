@import '_helpers';
@import '_variables';

.visible-only-mobile {
	display: none !important;
}

/* Desktops (max 1640px) ----------- */
@media only screen and (max-width : 1640px) {
	body {
		.licenseBar {
			.licenseBarContainer {
				& > .licenseBar__element {
					&.licenseBar__element-crests {
						& > img {
							margin-left: 25px !important;
							max-width: 70px;
						}
					}
				}
			}
		}
		.livecasino_container_main {
		    width: 100%;
		}
	}
}
/* Desktops (max 1600px) ----------- */
@media only screen and (max-width : 1600px) {
	body.mainMenu--show {
		.sliderBlock {
		    .main-slider {
		        .slider {
		            max-height: 448px;
		        }
		    }
		}
	}
}

/* Desktops (max 1540px) ----------- */
@media only screen and (max-width : 1540px) {
	body {
		.licenseBar {
			.licenseBarContainer {
				& > .licenseBar__element {
					&.licenseBar__element-crests {
						& > img {
							margin-left: 15px !important;
							max-width: 60px;
						}
					}
				}
			}
		}
		.sliderBlock {
		    .main-slider {
		        .slider {
		            .slide {
		                div[class^="slide-item"] {
		                    .button-wrapper {
		                        .button-wrapper-inside {
									.limited {
										font-size: 24px;
									}
									.cosmic-madness {
										font-size: 24px !important;
										//line-height: 75px !important;
									}
									.deposit {
										font-size: 34px !important;
										line-height: 42px !important;;
									}
									.button--slider {
										top: 5px !important;
										padding: 10px 30px !important;
										width: auto !important;
									}
									.slider-link {
										font-size: 12px !important;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

/* Desktops (max 1440px) ----------- */
@media only screen and (max-width : 1440px) {
	body {
		header {
			.rightColumn {
				.searchBar {
					.searchBar__search {
				    	padding-left: 20px !important;
					}
				}
			}
		}
		main {
			.container {
				padding: 0 40px 20px 44px !important;

				&.container--first {
					padding-top: 0 !important; //40px !important;
				}
			}
			.rightColumn {
				img {
					max-width: 100%;
				}
				h1,h2 {
					&.bottomDecorator {
						padding-top: 20px;
						margin: 0 0 40px 0;

						&::before {
							top: 27px !important;
						}
					}
				}
			}
			.licenseBar {
				.licenseBarContainer {
					padding: 0 30px !important;

					& > .licenseBar__element {
						&.licenseBar__element-rightAlign {
							p {
								font-size: 11px !important;
								line-height: 17px !important;
							}
						}
						.licenseBar__element-why {
							width: 100px !important;
							top: 24px !important;
						}
					}
				}
			}
		}
		&.fullscreen {
			main {
				.gameContainer {
					.gameContainer__leftColumn {
						width: 100% !important;
					}
				}
			}
		}
		.sliderBlock {
		    .main-slider {
		        .slider {
		            .slide {
		                div[class^="slide-item"] {
		                    .button-wrapper {
		                        .button-wrapper-inside {
									.cosmic-madness {
										font-size: 22px !important;
										//line-height: 75px !important;
									}
									.deposit {
										font-size: 32px !important;
										line-height: 41px !important;;
									}
									.button--slider {
										top: 5px !important;
										padding: 10px 30px !important;
										width: auto !important;
									}
									.slider-link {
										font-size: 12px !important;
									}
								}
							}
						}
					}
				}
			}
		}
		&.mainMenu--show {
			.sliderBlock {
			    .main-slider {
			        .slider {
			        	max-height: 403px;
			        }
			    }
			}
		}
	}
    .contactComponent {
        padding: 40px 90px !important;
    }
}

/* Desktops (max 1379px) ----------- */
@media only screen and (max-width : 1379px) {
	.winnersContainer {
		.winnersSlider {
	        .item {
				p {
					&.userName {
						padding-left: 20px;
						width: calc(100% - 60px) !important;

					}
					&.winFactor {
						right: 20px !important;
					}
				}
	        }
	    }
	}
	body {
		.sliderBlock {
		    .main-slider {
		        .slider {
		            .slide {
		                div[class^="slide-item"] {
		                    .button-wrapper {
		                        .button-wrapper-inside {
									.cosmic-madness {
										font-size: 19px !important;
										//line-height: 75px !important;
									}
									.deposit {
										font-size: 28px !important;
										line-height: 38px !important;;
									}
									.button--slider {
										top: 5px !important;
										padding: 10px 30px !important;
										width: auto !important;
									}
									.slider-link {
										font-size: 12px !important;
									}
								}
							}
						}
					}
				}
			}
		}
	}

}
/* Laptop (max 1366px) ----------- */
@media only screen and (max-width : 1366px) {
	body.mainMenu--show {
		.sliderBlock {
	    	.main-slider {
	        	.slider {
	            	max-height: 380px;
	            }
	        }
	    }
	}
}

/* Desktops (max 1340px) ----------- */
@media only screen and (max-width : 1340px) {
	body {
		main {
			.licenseBar {
				.licenseBarContainer {
					zoom: 90%;
				}
			}
		}
		.sliderBlock {
		    .main-slider {
		        .slider {
		            .slide {
		                div[class^="slide-item"] {
		                    .button-wrapper {
		                        .button-wrapper-inside {
									.cosmic-madness {
										font-size: 19px !important;
										//line-height: 75px !important;
									}
									.deposit {
										font-size: 28px !important;
										line-height: 38px !important;;
									}
									.button--slider {
										top: 5px !important;
										padding: 10px 30px !important;
										width: auto !important;
									}
									.slider-link {
										font-size: 12px !important;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

/* Desktops (max 1280px) ----------- */
@media only screen and (max-width : 1280px) {
	body.mainMenu--show {
		.sliderBlock {
	    	.main-slider {
	        	.slider {
	            	max-height: 353px;
	            }
	        }
	    }
	}
}

/* Desktops (max 1220px) ----------- */
@media only screen and (max-width : 1220px) {
	body {
		main {
			.licenseBar {
				.licenseBarContainer {
					zoom: 80%;
				}
			}
		}

		.footerMenu {
			.footerMenu__item {

				&.footerMenu__item--left {
					width: 260px !important;
				}

				&.footerMenu__item--center {
					width: calc(100% - 520px);

					.mergedServiceList {
						display: block !important;
					}
					.slicedServiceList {
						display: none !important;
					}
				}
				&.footerMenu__item--right {
					width: 260px !important;
				}

				.columns {
					& > .column {
						width: 50%;
					}
				}
			}
		}

		.footerCopyright {
			.footerCopyright__item {
				&.footerCopyright__item--left {
					width: 260px !important;

					&::before {
						left: 220px;
					}
				}

				&.footerCopyright__item--center {
					width: calc(100% - 520px);
				}

				&.footerCopyright__item--right {
					width: 260px !important;
				}
			}
		}
		.logosContainer {
			.logosSlider {
				.slider.platform-server {
					.slide {
					    .logosSlider__item {
    						flex: 1 0 25%;
					    }
					}
				}
			}
		}
	}
    .contactComponent {
        padding: 40px 60px !important;
    }
}

/* Desktops (max 1120px) ----------- */
@media only screen and (max-width : 1120px) {
	body {
		main {
			.licenseBar {
				.licenseBarContainer {
					zoom: 70%;
				}
			}

			.sliderBlock {
			    .main-slider {
			        .slider {
			            .slide {
			                div[class^="slide-item"] {
			                    .button-wrapper {
			                        .button-wrapper-inside {
										.cosmic-madness {
											font-size: 18px !important;
											//line-height: 75px !important;
										}
										.deposit {
											font-size: 26px !important;
											line-height: 36px !important;;
										}
										.button--slider {
											top: 5px !important;
											padding: 8px 25px !important;
											width: auto !important;
										}
										.slider-link {
											font-size: 12px !important;
										}
										.button {
										    top: 5px!important;
										    padding: 10px 30px!important;
										    width: auto!important;
										}
									}
								}
							}
						}
					}
				}
			}
		}

		.footerMenu {
			.footerMenu__item {
				&.footerMenu__item--right {
					padding-left: 10px !important;
				}
			}
		}

		.footerCopyright {
			.footerCopyright__item {
				&.footerCopyright__item--right {
					padding-left: 0;
					width: 100% !important;
					text-align: center;

					&::before {
						display: none !important;
					}
				}

				&.footerCopyright__item--center {
					width: calc(100% - 300px) !important;
				}
			}
		}
	}
    .contactComponent {
        padding: 40px 30px !important;
    }
}

/* Desktops (max 1024px) ----------- */
@media only screen and (max-width : 1024px) {
	body {
		header,
		main,
		footer {
			.leftColumn {
				width: 60px !important;
			}

			.rightColumn {
				width: calc(100% - 60px) !important;
			}
		}

		main {
			.hamburger {
				//left: 15px !important;
			}

			.leftColumn {
				.logo {
					width: 200px !important;

					.logo__element {
						background-size: 100px;
						background-position: 43px 54px !important;
					}
				}
				.mainMenuWrapper {
					.mainMenu {
						& > .mainMenu__item {
							.mainMenu__item-anchor {
								font-size: 12px !important;
								height: auto !important;
								padding: 5px 25px 5px 24px !important;

								.fa,
								.icon {
									font-size: 14px !important;
									margin-right: 5px;
								}
							}

							&:first-child {
								margin-top: 0 !important;
							}
						}
					}
				}
			}

			.rightColumn {
				width: 100% !important;
				padding-left: 60px !important;
			}
            .licenseBar {
                .licenseBarContainer {
                    flex-direction: row !important;
                }
            }
            .sliderBlock {
			    .main-slider {
			        .slider {
			            .slide {
			                div[class^="slide-item"] {
			                    .button-wrapper {
			                        .button-wrapper-inside {
										.cosmic-madness {
											font-size: 16px !important;
											margin-bottom: 5px !important;
											//line-height: 75px !important;
										}
										.deposit {
											font-size: 24px !important;
											line-height: 34px !important;
											margin-bottom: 5px !important;
										}
										.button--slider {
											top: 5px !important;
											padding: 6px 25px !important;
											width: auto !important;
										}
										.slider-link {
											font-size: 12px !important;
											margin-top: 25px !important;
										}
									}
								}
							}
						}
					}
				}
			}
		}

		&.mainMenu--show {
			.leftColumn {
				width: 200px !important;
			}

			.rightColumn {
				width: calc(100% - 200px) !important;
			}

			main {
				.rightColumn {
				    padding-left: 200px !important;
				    width: 100% !important;
				}
			}
		}

		&.mainMenu--show:not(.mainMenuUserInteraction--hide) {
			main {
				.rightColumn {
					padding-left: 200px !important;
				}
			}
			.game-sidebar-visible {
				width: calc(100% - 200px) !important;
				left: 200px !important;
			}
		}

		header {
			.rightColumn {
				.searchBar {
					position: relative;
					margin-right: -3px;

					.searchBar__search {
						width: 0;
						padding: 0 0 0 0 !important;
					}

					.searchBar__fakeSubmit {
						display: inline-block !important;
					}

					/*.searchBar__fakeSubmit,
					.searchBar__submit {
						box-shadow: none;
						background: {
							color: transparent;
							image: none;
						};
					}*/

					&.searchBar--show {
						position: absolute;
					    z-index: 1;
					    width: calc(100% - 69px);

					    .searchBar__mask {
					    	width: 100vw;
							height: 100vh;
							opacity: 1;
					    }

						.searchBar__fakeSubmit {
							display: none !important;
						}

						.searchBar__fakeSubmit,
						.searchBar__submit {
							background: {
								image: url('#{$images-path}/red-button-background.jpg');
								color: $primary-red;
							};
						}

						.searchBar__search {
							width: calc(100% - #{$header-search-height});
	    					padding: 0 10px 0 20px !important;
						}
					}

					&::before {
						position: absolute;
						display: block;
						width: 1px;
						height: 100%;
						left: 0;
						top: 0;
						content: '';
						opacity: 0.2;
						background: {
							image: url('#{$images-path}/vertical-dot-separator.png');
							position: left top;
							repeat: repeat-y;
						};
					}
				}
			}
		}
	}
	body {
		.sliderBlock {
	    	.main-slider {
	        	.slider {
	            	max-height: 336px;
	            }
	        }
	    }
	}
}

/* Desktops (max 980px) ----------- */
@media only screen and (max-width : 980px) {

	.visible-only-mobile {
		display: block !important;
	}

	.visible-only-desktop {
		display: none !important;
	}

	.goToTop {
		width: 50px !important;
	    height: 50px !important;
	    bottom: 58px !important;
	    right: 20px !important;
	    line-height: 50px !important;
	}

	.stickyFooter {
		display: block !important;

		&.stickyFooter--logged {
			.stickyFooter__item {
        float: left;
				width: 40%;
				min-width: auto;
				&.stickyFooter__item--bonus {
					width: 20%;
					position: relative;
					text-align: center;
					.itemBonus--box {
						width: 40px;
						margin: 0 auto;
						position: relative;
					}
				}
				&.stickyFooter__item--balance {
					border: 5px solid $footer-background-color;
					padding: 8px 0;
					background: $primary-pure-black;
				}
			}
		}
		.stickyFooter__item {
			a {

				i.left-position {
					margin-right: 8px;
				}

				i.right-position {
					margin-left: 8px;
				}
			}

			.label,
			.value {
				text-transform: uppercase;
				text-align: center;
				width: 100%;
				display: block;
				letter-spacing: 0;
				margin: 0;
			}

			.balance {
				position: relative;
			}

			.label {
				font-size: 8px;
				font-weight: 700;
				line-height: 8px;
				margin-bottom: 2px;
				color: $label-color-main;
			}

			.value {
				font-size: 19px;
				font-weight: 700;
				line-height: 19px;
				letter-spacing: -1px;
			}
		}
		.stickyFooter__item--bonus {

			.headerMenu__itemBonus {
				&::after {
					transition: transform 0.6s ease-in-out;
					display: none;
					position: absolute;
					top: 0px;
					left: auto;
					right: 0px;
					content: '0';
					background-color: $primary-red;
					color: $primary-white;
					text-align: center;
					width: $header-counter-size;
					height: $header-counter-size;
					line-height: $header-counter-size;
					font-size: 9px;
					border-radius: 90%;
					text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
					font-weight: 700;
					overflow: hidden;
					transform: scale(1);
					opacity: 1;
				}

				&:not([data-counter="0"]):not([data-counter=""]) {
					&::after {
						transform: scale(1);
						opacity: 1;
						display: block;
						content: attr(data-counter);
					}
				}
				cursor: pointer;

				&::before {
					position: relative;
					top: 0px;
					left: 2px;
					content: "";
					font-family: FontAwesome;
					width: inherit;
					height: inherit;
					font-size: 25px;
					color: $primary-white;
					text-align: center;
					margin-left: -4px;
					text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
					content: "\F06B";
				}

				&.loading {
					&::after {
						transform: scale(0) !important;
						opacity: 0.33 !important;
					}
				}
			}

			.headerMenu__itemClose {
				cursor: pointer;

				&::before {
					position: relative;
					top: 0px;
					left: 3px;
					content: "\F00D";
					font-family: FontAwesome;
					width: inherit;
					height: inherit;
					font-size: 21px;
					color: $primary-white;
					text-align: center;
					margin-left: -4px;
					text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
				}
			}
			&.show {
				display: block;

				.headerMenu__itemBonus-content {
					display: block;
				}
			}

			.headerMenu__itemBonus-content {
				display: none;
				position: fixed;
				width: 100vw;
				left: 0;
				top: 50px;
				height: calc(100% - 104px);
				background-color: $primary-black;
    			background-image: url('#{$images-path}/background.jpg');
    			overflow-y: auto;

				&.show {
					display: block;
				}

				.bonusContainer {
					padding: 20px !important;
					.bonusContainer__item {
						position: relative;
						padding: 0 0 31px 0;

						&::after {
							display: block;
							height: 1px;
							width: 100%;
							content: '';
							background: {
								image: url('#{$images-path}/horizontal-dot-separator.png');
								repeat: repeat-x;
							};
							opacity: 0.2;
							position: absolute;
							top: auto;
							bottom: 21px;
						}
						.bonusWidget__box {
							display: table;
							width: 100%;
							.bonusWidget__graphic {
                                display: table-cell;
                                width: 20%;
                                text-align: center;
                                vertical-align: middle;
                                padding: 0 !important;
                                position: relative;
                                top: -10px;
                                .bonusWidget__game-img {
                                	width: 90%;
                                    margin: 0 auto;
                                }
                                .bonusWidget__icon {
                                    width: 90%;
                                    margin: 0 auto;
                                    position: relative;

                                    .fa {
                                        font-size: 6vw;
                                        color: $gray-color-1;
                                        position: absolute;
										left: 50%;
										top: 50%;
										transform: translate(-50%,-55%);
											transform: translate3d(-50%,-55%,0);
                                    }
                                }
                            }
                            .bonusWidget__main {
                            	display: table-cell;
                            	vertical-align: middle;
                            	padding-top: 25px;
                            	.button--orange {
                            		background-color: rgba(245, 126, 46, 0.8);
                            	}
                            }
                        }

						&:last-child,
						&.bonusContainer__item--last-child {
							padding-bottom: 0 !important;
							&::after {
								display: none;
							}
						}

						.decorator {
							position: relative;
							display: block;
							background: {
								image: url('#{$images-path}/crest-bonus-background.png');
								repeat: repeat-x;
							};
							width: 70px;
							height: 72px;
							margin: 0 auto -10px auto;
							z-index: 2;

							.image {
								position: absolute;
								max-width: 100%;
								border-radius: 90%;
							    overflow: hidden;
							    width: 48px;
							    height: 47px;
							    top: 11px;
						        left: 11px;
								background: {
									position: center;
									repeat: no-repeat;
									size: auto 119%;
								};
							}

							.fa,
							.icon {
								width: 70px;
								height: 72px;
								line-height: 69px;
								text-align: center;
								font-size: 29px;
								color: $footer-background-color;
								text-shadow: -1px 1px 1px rgba(255, 255, 255, 0.55);

								&.fa-eur {
									position: relative;
									margin-left: -2px;
								}
							}
						}

						.button {
							white-space: normal !important;
							width: 100%;
							display: inline-block;
							line-height: 22px;
							padding: 9px 16px;
							position: relative;
							margin-top: -19px;
						}
					}
				}
			}

			&.show {
				display: block;

				.headerMenu__itemBonus-content {
					display: block;
				}
			}
		}
	}

	body {

        h2::before {
            top: 27px;
        }

		header {
			.headerContainer {
				height: 50px;

				&.headerContainerMobile {
					.headerMenu {
						display: flex;
						list-style: none;
						letter-spacing: -10px;
						margin: 0;
						height: inherit;
						padding: 0;

						.headerMenu__item {
							width: 50px;
							transition: all 0.25s ease-in-out;
							display: inline-block;
							vertical-align: top;
							height: inherit;
							position: relative;
							letter-spacing: 0;
							padding: 0 1px;
							cursor: default;
							border-bottom: 2px solid transparent;
							text-align: center;
							line-height: 50px;

							&::before {
								position: absolute;
								display: block;
								width: 1px;
								height: 100%;
								left: 0;
								top: 0;
								content: '';
								opacity: 0.2;
								background: {
									image: url('#{$images-path}/vertical-dot-separator.png');
									position: left top;
									repeat: repeat-y;
								};
							}
							&.headerMenu__item--responsible {
								padding:5px 0 0;
								text-align: center;
		                        img {
		                            height: 85%;
		                            padding-top: 15%;
		                        }
		                    }
							.headerMenu__item-content {
								background-color: $primary-black;
								left:0;
								top: 50px;
								position: fixed;
								width: 100vw;
								height: calc(100vh - 50px);
								padding: 0;
								display: none;
								overflow: hidden;

								&.headerMenu__itemSearch-content {
									.headerMenu__item-contentInside {
										padding: 0;

										.searchBar {
											width: 100%;

											.searchBar__search {
												width: calc(100% - 50px);
												padding: 0 10px 0 20px;
											    height: 50px;
											}

											.searchBar__submit,
											.searchBar__fakeSubmit {
												width: 50px !important;
												height: 50px !important;
												padding: 0 !important;

												&::before {
													line-height: 50px;
												}
											}

											.searchBar__suggestWrapper {
												height: auto;
												opacity: 1;
											}
											&.autosuggest--show {
												.searchBar__mask {
													width: 0;
													height: 0;
													opacity: 0;
												}
											}
										}
									}

									.searchBar__suggestWrapper {
										position: absolute !important;
										max-height: calc(100vh - 100px) !important;
										top: 0;
										left: 0;
										height: auto;
										opacity: 1;
										margin-top: 50px !important;
									}
								}

								&.show {
									display: block !important;
								}

								.bonusContainer {
									padding: 30px 20px 20px 20px !important;
								}

								.bonusContainer,
								.headerMenu__item-contentInside {
									padding: 20px;
									overflow: {
										x: hidden;
										y: auto;
									}
									-webkit-overflow-scrolling: touch;
									height: inherit;
								}

								.language-switcher-wrapper {
									position: relative;
									margin: 10px 0;

									&.language-switcher-wrapper--active {
										language-switcher {
											.languageSwitcher__dropdown {
												width: 100% !important;
												height: auto !important;
												z-index: 9999 !important;
												opacity: 1 !important;
												top: -24px !important;
												left: 0 !important;

												.languageSwitcher__dropdown-inside {
													width: 100%;
													padding: 15px 10px 8px 10px !important;

													.languageSwitcher__dropdown-inside_item {
													    display: inline;
													}
												}
											}
										}
									}
								}

								.mainMenu {
									//

									& > .mainMenu__item {

										.mainMenu__item-anchor {
											text-align: left;
										    padding: 0 35px 0 26px;
									        border-left: 4px solid transparent;
									        cursor: pointer;

										    &:hover {
										    	&::after {
													display: none;
										    	}

										    }
										    &::after {
												display: none;
									    	}

											span {
												opacity: 1;

											}
											[class^=icon-] {
												top: 7px;
											}
                                            .all-games-icon {
                                                margin-right: 5px;
                                                + span {
                                                    top: 1px;
                                                }
                                            }
                                            .mainMenu__custom-img {
												top: 9px;
                                            }
										}
									}
								}
							}

							a {
								&:not(.searchBar__submit) {
									width: 100%;
									height: 100%;
									line-height: inherit;
									text-align: center;
									display: inline-block;
								}
							}

							&.headerMenu__item--inactive {
								pointer-events: none;

								a {
									opacity: 0.33;
									cursor: default;

									&:hover {
					    				background-color: transparent !important;
									}
								}
							}

							&:hover {
								background-color: rgba(255, 255, 255, 0.1);
							}

							&.headerMenu__item--logo {
								width: calc(100% - 200px);
								text-align: center;
								padding: 0;
								padding-left: 50px;

								.headerMenu__itemLogo {
									position: relative;
									display: inline-block;
									width: 122px;
									height: 50px;
									cursor: pointer;

									&::before,
									&::after {
										left: 0;
										top: 0;
										position: absolute;
										content: '';
										display: block;
										width: 100%;
										height: 100%;
										background: {
											color: transparent;
										};
									}

									&::before {
										left: 0;
										top: 0;
										position: absolute;
										content: '';
										display: block;
										width: 100%;
										height: 100%;
										background: {
											image: url('#{$images-path}/red-button-background.jpg');
											color: $primary-red;
										};
									}

									&::after {
										left: 0;
										top: 0;
										position: absolute;
										content: '';
										display: block;
										width: 100%;
										height: 100%;
										background: {
											image: url('#{$images-path}/logo-min.png');
											position: center;
											repeat: no-repeat;
											size: cover;
										};
										@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi) {
											background-image: url('#{$images-path}/logo-min2x.png');
										}
									}
								}

								&:hover{
									background-color: transparent;
								}
							}



							.headerMenu__itemBonus {
								&::after {
									transition: transform 0.6s ease-in-out;
									display: none;
									position: absolute;
									top: 9px;
									left: auto;
									right: 11px;
									content: '0';
									background-color: $primary-red;
									color: $primary-white;
									text-align: center;
									width: $header-counter-size;
									height: $header-counter-size;
									line-height: $header-counter-size;
									font-size: 9px;
									border-radius: 90%;
									text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
									font-weight: 700;
									overflow: hidden;
									transform: scale(1);
									opacity: 1;
								}

								&:not([data-counter="0"]):not([data-counter=""]) {
									&::after {
										transform: scale(1);
										opacity: 1;
										display: block;
										content: attr(data-counter);
									}
								}

								&.loading {
									&::after {
										transform: scale(0) !important;
										opacity: 0.33 !important;
									}
								}
							}

							.headerMenu__itemHamburger,
							.headerMenu__itemSearch,
							.headerMenu__itemBonus,
              .headerMenu__itemUserPanel,
              .headerMenu__itemSupport {
								cursor: pointer;

								&::before {
									position: relative;
									top: -9px;
									left: 2px;
									content: "";
									font-family: FontAwesome;
									width: inherit;
									height: inherit;
									font-size: 21px;
									color: $primary-white;
									text-align: center;
									line-height: $header-search-height;
									margin-left: -4px;
									text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
								}
							}

							.headerMenu__itemHamburger-content {
								.headerMenu__item-contentInside {
									padding-left: 0 !important;
									padding-right: 0 !important;
								}
							}

							.headerMenu__itemUserPanel-content {
								.headerMenu__item-contentInside {
									.userPanel__container {
										opacity: 1;
										transform: translate(0, 0);
										width: 100%;
										background: {
											color: $primary-black;
											image: none;
										};

										.leftIcon {
											font-size: 30px;
											float: left;
											margin: 5px 10px 5px 0;
										}

										.user {
    										line-height: 22px;
										}

										.lastLogin {
											line-height: 16px;
										}

										.myProfileAndLogout {
											display: flex;
											line-height: 10px;
											margin-top: 0;

											.myProfileAndLogout__item {
												width: 50%;
												padding: 9px 16px;
												margin: 10px 0;
											}
										}

										.accountAndSportsbook__item {
											width: 100%;
											line-height: 10px;

											.link {
												padding: 10px 0;
											}
										}

										.button {
											line-height: 22px;
    										padding: 9px 16px;

											&.button--bottomMargin {
												margin-bottom: 10px;
											}
										}

										.horizontal-separator {
											height: 31px;

											&::after {
												top: 16px;
											}
										}
									}
								}
							}

							.headerMenu__itemClose {
								cursor: pointer;

								&::before {
									position: relative;
									top: -9px;
									left: 2px;
									content: "\F00D";
									font-family: FontAwesome;
									width: inherit;
									height: inherit;
									font-size: 21px;
									color: $primary-white;
									text-align: center;
									line-height: $header-search-height;
									margin-left: -4px;
									text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
								}
							}

							.headerMenu__itemHamburger {
								&::before {
									content: "\f0c9"; /* fa-bars icon */
								}
							}
							.headerMenu__itemSearch {
								&::before {
									content: "\f002"; /* fa-search icon */
								}
							}
							.headerMenu__itemBonus {
								&::before {
									content: "\F06B"; /* fa-usd prixe */
									top: -7px;
								}
              }
              .headerMenu__itemSupport {
								&::before {
									content: "\f0e6"; /*  fa-comments-o */
								}
							}
							.headerMenu__itemUserPanel {
								&::before {
									content: "\f2be"; /* fa-user-circle-o icon */
								}

								&.withAvatar {
									.userPicture {
										width: 21px;
										height: 21px;
										position: relative;
										top: 5px;
										border-radius: 90%;
									}
									&::before {
										display: none;
									}
								}
							}
						}
					}
				}
			}
		}

		main {
			min-height: calc(100vh - 50px);
			padding-top: 50px;

			.container {
				padding: 20px !important;

				&.container--first {
				    padding-top: 0 !important;
				}
                &.container--last {
				    padding-bottom: 30px !important;
				}
			}

			.licenseBar {
				margin-left: -20px;
				width: calc( 100% + 40px ) !important;
				.licenseBarContainer {
					flex-direction: column !important;
				}
			}

			.rightColumn {
				padding-left: 0px !important;

				h2, h1 {
					font-size: 20px;
					line-height: 31px !important;
					padding-top: 20px;
					margin-bottom: 20px;
				}
			}

			.gameList {
				//
			}

			.container {
				.columnContainer {
					.columnContainer__column {
						&.columnContainer__column--left {
							width: 100% !important;
							padding-right: 0 !important;
						}

						&.columnContainer__column--right {
							width: 100% !important;
							display: flex !important;

							& > section {
								width: 50% !important;
								margin-left: 10px !important;

								&:first-child {
									margin-left: 0 !important;
									margin-right: 10px;
								}
							}

							.qualityPromise {
								height: calc(100% - 93px);

								.qualityPromise__column {
									&.qualityPromise__column--left {
										width: 100px !important;

										img {
											width: 100%;
										}
									}

									&.qualityPromise__column--right {
										width: calc(100% - 100px) !important;
										padding: 0 !important;
									}
								}
							}
						}
					}
				}
        .gameContainer {
          &.cinemaMode {
            .gameContainer__rightColumn {
              .gameContainer__rightColumn-inside {
                .stickyTop, .stickyBottom {
                  display: none;
                }
              }
            }
          }
          .gameContainer__leftColumn {
			text-align: center;
          }
        }

				&.container--topPadding {
					padding-bottom: 40px !important;
				}
			}

			.footerMenu {
				.footerMenu__item {
					&.footerMenu__item--left {
						width: 100% !important;
						padding: 0 !important;
						display: block;
						margin: 0 auto 40px auto;
					}

					&.footerMenu__item--center {
						width: 66.6% !important;

						.columns {
							text-align: center;
						}
					}

					&.footerMenu__item--right {
						width: 33.3% !important;
						padding: 0 !important;
						text-align: center;
					}

					.crestList {
						.crestList__item {
							padding: 0 20px !important;
							width: 25% !important;
						    text-align: center !important;
						}
					}
				}
			}
			.sliderBlock {
				.main-slider {
			        .slider {
			        	margin-bottom: -30px;
			            .slide {
			                div[class^="slide-item"] {
	                  			.button-wrapper {
	                  				flex-flow: row wrap;
	                  				-webkit-box-align: center;
							        -ms-flex-align: center;
							        align-items: center;
							        -webkit-box-pack: center;
							        -webkit-justify-content: center;
							        -ms-flex-pack: center;
							        justify-content: center;
							        left: 50%;
							        transform: translate(-183px,0);
							        top: -15px;
							        width: auto;
									.button-wrapper-link {
										text-decoration: none;
										cursor: pointer;
									}
									.button-wrapper-inside {
										&.visible-only-desktop {
											display: none !important;
										}
										background-color: rgba(0, 0, 0, 0.72);
							        	padding: 20px !important;
								        text-align: center !important;
								        width: 375px;
								        top: 0 !important;
										.cosmic-madness {
											font-size: 16px !important;
											margin-bottom: 10px !important;
											display: block !important;
											//line-height: 75px !important;

										}
										.deposit {
											font-size: 22px !important;
											line-height: 30px !important;
											margin-bottom: 10px !important;
											display: block !important;
										}
										.button--slider {
											top: 5px !important;
											padding: 6px 15px !important;
											width: auto !important;
											font-size: 18px;
											clear: both;
											float: left;
											text-transform: none;
										}
										.slider-link {
											font-size: 12px !important;
											margin-top: 20px !important;
											float: right;
										}
									}
								}
							}
						}
					}
					.slider-paginator {
						display: none !important;
					}
				}
			}

			.gameList {
				&.gameList__livecasino {
					.liveCasino__wrapper {
						.liveCasino__item {
							.gameList__box {
								.gameList__title_wrapper {
									top: 42%;
									h2 {
										font-size: 28px;
										padding: 10px 15px;
									}
								}
							}
						}
					}
				}
			}
		}
		.payments {
			.paymentsSlider {
				padding-top: 14px !important;
				.slider.platform-server {
					overflow: hidden !important;
					.slide {
						>div {
							text-align: center;
							width: 50%;
							&.clone {
								display: none;
							}
							.licenseBar__element-image {
								display: inline-block;
							}
						}
					}
				}
			}
		}
		.vendors {
			.paymentsSlider {
				.slider.platform-server {
					height: 50px !important;
					overflow: hidden !important;
					.slide {
						>div {
							text-align: center;
							width: 50%;
							.licenseBar__element-image {
								display: inline-block;
							}
						}
					}
				}
			}
		}
		.logosContainer {
			.logosSlider {
				.slider.platform-server {
					.slide {
					    .logosSlider__item {
    						flex: 1 0 33.333333%;
					    }
					}
				}
			}
		}
	}
  #gdpr-cookie-bar {
    font-size: 0.8rem;
    padding: 5px 10px 60px;
    z-index: 1202;
  }
  .owl-theme .owl-nav.disabled + .owl-dots {
  	top: -62px !important;
  }
  .game-sidebar {
    width: 100%;
    top: 49px;
    &-visible {
        left: 0;

    }
   }
   body.mainMenu--show:not(.mainMenuUserInteraction--hide) {
		app-layout-core.platform-server {
			main {
				.rightColumn {
					padding-left: 0px !important;
				}
			}
		}
	}
}


/* Tablet (max 906px) ----------- */
@media all and (max-width: 936px) {
	body {
		//
	}
}

/* Desktops (max 899px) ----------- */
@media all and (max-width: 899px) {
	body {
		main {
			.gameList {
				& > .gameList__item {

					//
				}
			}

			.jackpots {
				.jackpots__element {
					.jackpots__element-column {
						.button {
							font-size: 12px !important;
						}
					}
				}
			}

			.winnersContainer {
				.winnersSlider {
					.slide {
						& > .button {
							font-size: 12px !important;
						}
					}
				}
			}
		}

		header {
			.rightColumn {
				.headerMenu {
				    & > .headerMenu__item {

				    	&:last-child {
				    		//
				    	}
				    }
				}
			}
		}
	}
	.winnersContainer {
		.winnersSlider {
	        .item {
				p {
					&.userName {
						padding-left: 10px;
						width: calc(100% - 50px) !important;

					}
					&.winFactor {
						right: 10px !important;
					}
				}
	        }
	    }
	}
	input, textarea, select {
	  font-size: 16px !important;
	}
}

/* Desktops (max 780px) ----------- */
@media all and (max-width: 780px) {
	body {
		main {
			.container {
				.columnContainer {
					.columnContainer__column {
						&.columnContainer__column--right {
							display: block !important;

							.qualityPromise {
								height: auto !important;

								.qualityPromise__column {
									&.qualityPromise__column--left {
										width: 110px !important;

										img {
											width: 100%;
										}
									}

									&.qualityPromise__column--right {
										width: calc(100% - 110px) !important;
										padding: 0 !important;
									}
								}
							}

							& > section {
								width: 100% !important;
								margin-left: 0 !important;
							}
						}
					}
				}
			}
		}
	}
}

/* Tablets (max 797px) ----------- */
@media all and (max-width: 797px) {
	body {
        main {
            .gameList {
                &.gameList__livecasino {
					.top-games {
						.liveCasino__wrapper {
							&.top {
								.liveCasino__item {
									.gameList__box {
										.gameList__vendor_wrapper {
											.gameList__vendor_bg {
												justify-content: center;
											}
										}
									}
								}
							}
						}
					}
					.second-games {
                        .liveCasino__wrapper {
							&:nth-child(n + 2) {
								margin-top: 2%;
							}
						}
					}
					.third-games {
						.liveCasino__wrapper {
							&.third {
								.liveCasino__item {
									.gameList__box {
										.gameList__vendor_wrapper {
											.gameList__vendor_bg {
												justify-content: center;
												width: auto;
												.gameList__vendor {
													width: 75%;
												}
											}
										}
									}
								}
							}
						}
					}
					.bottom-games {
                        .liveCasino__wrapper {
							&.fourth,
							&.bottom {
								width: 49% !important;
								margin-left: 0;
								&:nth-child(even) {
									margin-left: 2%;
								}
								&:nth-child(n + 3) {
									margin-top: 2%;
								}
								.liveCasino__item {
									.gameList__box {
										.gameList__vendor_wrapper {
											.gameList__vendor_bg {
												width: 75%;
												.gameList__vendor {
													width: auto;
												}
											}
										}
									}
								}
							}
							&.bottom {
								&.third {
									.liveCasino__item {
										.gameList__box {
											.gameList__vendor_wrapper {
												left: auto;
												right: 0;
												width: auto;
											}
										}
									}
								}
								.liveCasino__item {
									.gameList__box {
										.gameList__vendor_wrapper {
											.gameList__vendor_bg {
												margin-left: 25%;
											}
										}
									}
								}
							}
                        }
                    }
                	.liveCasino__wrapper {
						width: 100% !important;
						&:nth-child(2) {
						    margin-left: 0;
						}
						&.top {
							.liveCasino__item {
								.gameList__box {
									.gameList__vendor_wrapper {
										.gameList__vendor_bg {
											width: auto;
											.gameList__vendor {
												width: 75%;
											}
										}
									}
								}
							}
						}
						&.second {
							&.even {
								margin-left: 0;
							}
						}
						&.liveCasino__wrapper_three, &.liveCasino__wrapper_one {
							.liveCasino__item {
								.gameList__box {
									.button {
										bottom: -38px;
									}
								}
							}
						}
						.liveCasino__item {
							.gameList__box {
								.gameList__title_wrapper {
									h2 {
										font-size: 22px;
										padding: 5px 15px;
									}
								}
								.gameList__boxImg {
									width: 100%;
									min-height: 200px;
								}
								.button {
									bottom: -44px;
								    left: 0;
								    width: 100%;
								    padding-left: 0;
								    padding-right: 0;
								}
								.live-casino-buttons {
									position: static;
									visibility:visible;
									opacity: 1;
									transform: none;
								}
								.gameList__boxLive {
									font-size: 18px;
									padding: 8px;
								}
								.gameList__vendor_wrapper {
									.gameList__vendor_bg {
										width: 75%;
									}
								}
							}
						}
						&:not(.liveCasino__wrapper_three) {
							.button.button--red {
								position: static;
							    visibility: visible;
							    opacity: 1;
							    transform: none;
							    height: 38px;
							    line-height: normal;
							}
							.gameList__vendor {
								bottom: 38px !important;
							}
						}
                	}
                }
            }
        }
	}
}

/* Tablets (max 736px) ----------- */
@media all and (max-width: 736px) {
	body {
		.sliderBlock {
		    .main-slider {
		        .slider {
		            max-height: 263px;
		        }
		    }
		}
	}
}

/* Tablets (max 720px) ----------- */
@media all and (max-width: 720px) {

	.stickyFooter {
		width: 100%;

		.stickyFooter__item {
			width: 50%;
		}
	}

	body {
		.footerCopyright {
			.footerCopyright__item {
				&.footerCopyright__item--left {
					width: 100% !important;
    				padding: 0 !important;

    				&::before {
    					display: none;
    				}
				}

				&.footerCopyright__item--center {
					width: 100% !important;
					text-align: center;
				}

				.footerLogos {
					padding-top: 0;
				}
			}
		}

		.footerMenu {
			.footerMenu__item {
				.columns {
					& > .column {
						h4 {
							font-size: 16px !important;
							margin-bottom: 12px !important;
						}

						ul {
							li {
								a {
									font-size: 11px !important;
									line-height: 15px !important;
									vertical-align: top;
								}
							}
						}
					}
				}
			}
		}
		main {
			.sliderBlock {
				.main-slider {
			        .slider {
			            .slide {
			                div[class^="slide-item"] {
	                  			.button-wrapper {
							        top: -20px;
									.button-wrapper-inside {
										padding: 10px !important;
										div, p {
											font-size: $baseFontSize !important;
											text-align: center !important;
										}
										.cosmic-madness {
											font-size: 16px !important;
											margin-bottom: 5px !important;
										}
										.deposit {
											font-size: 20px !important;
											line-height: 26px !important;
											margin-bottom: 5px !important;
										}
									}
								}
							}
						}
					}
				}
			}
		}
		.sliderBlock {
		    .main-slider {
		        .slider {
		            max-height: 258px;
		        }
		    }
		}
	}
}

/* Tablets (max 667px) ----------- */
@media all and (max-width: 667px) {
	body {
		.sliderBlock {
		    .main-slider {
		        .slider {
		            max-height: 242px;
		        }
		    }
		}
		main {
			.gameList {
				&.gameList__livecasino {
					.liveCasino__wrapper {
						.liveCasino__item {
							.gameList__box {
								.gameList__boxImg {
									min-height: 160px;
								}
								.gameItem-button-wrapper {
									.gameItem-button {
										padding: 5px 20px !important;
									}
								}
							}
						}
					}
					.top-games,
					.third-games {
						.liveCasino__wrapper {
							.liveCasino__item {
								.gameList__box {
									.gameList__title_wrapper {
										h2 {
											font-size: 16px;
											padding: 2px 10px;
										}
									}
									.gameList__boxLive {
										display: none;
									}
								}
							}
						}
					}
					.top-games {
						.liveCasino__wrapper {
							&.top {
								.liveCasino__item {
									.gameList__box {
										.gameList__boxLive {
											display: none;
										}
										.gameList__vendor_wrapper {
											.gameList__vendor_bg {
												.gameList__vendor {
													width: 60%;
												}
											}
										}
									}
								}
							}
						}
					}
					.third-games {
						.liveCasino__wrapper {
							&.third {
								.liveCasino__item {
									.gameList__box {
										.gameList__vendor_wrapper {
											.gameList__vendor_bg {
												.gameList__vendor {
													width: 60%;
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

/* Tablets (max 640px) ----------- */
@media all and (max-width: 640px) {
	body {
		.sliderBlock {
		    .main-slider {
		        .slider {
		            max-height: 233px;
		        }
		    }
		}
	}
}

/* Tablets (max 620px) ----------- */
@media all and (max-width: 620px) {
	body {
		main {
			.footerMenu {
				.footerMenu__item {
					.columns {
						display: block;
						width: 100% !important;

						& > .column {
							width: 100% !important;
							margin-bottom: 40px !important;
							padding: 0 !important;
						}
					}

					&.footerMenu__item--center {
						width: 100% !important;
					}

					&.footerMenu__item--right {
						width: 100% !important;

						.columns {
							& > .column {
								margin-bottom: 0 !important;
							}
						}
					}
				}
			}

		}
	}
	.winnersContainer {
		.winnersSlider {
	        .item {
				p {
					&.userName {
						padding-left: 0px;
						width: calc(100% - 40px) !important;

					}
					&.winFactor {
						right: 0px !important;
					}
				}
	        }
	    }
	}
}

@media all and (max-width: 568px) {
	body {
		.sliderBlock {
		    .main-slider {
		        .slider {
		            max-height: 210px;
		        }
		    }
		}
	}
}

@media all and (max-width: 534px) {
	body {
		.sliderBlock {
		    .main-slider {
		        .slider {
		            max-height: 199px;
		        }
		    }
		}
	}
}

@media all and (max-width: 520px) {
	.game-rating--disabled {
	    &::before {
	        width: 300px;
	    }
	}
	body {
		.logosContainer {
			.logosSlider {
				.slider.platform-server {
					.slide {
					    .logosSlider__item {
    						flex: 1 0 50%;
					    }
					}
				}
			}
		}
	}
}

/* Mobiles (max 480px) ----------- */
@media all and (max-width: 480px) {
	body {

		.login__dropdown,.webauthn_suggess__dropdown {
			width: 100% !important;
			top: 0 !important;

			.login__dropdown-wrapper,.webauthn_suggess__dropdown-wrapper {
				width: 100% !important;

				.login__dropdown-inside,.webauthn_suggess__dropdown-inside {
					padding: 30px;

					&.first {
					    padding: 30px;
					}
					&.paddingForDecorator {
						padding-bottom: 83px;
					}
					&.withoutVerticalPadding {
						padding-top: 0;
						padding-bottom: 0;
					}
				}
			}
		}

		.register__dropdown {
			width: 100% !important;
			top: 0 !important;

			.register__dropdown-wrapper {
				width: 100% !important;

				.register__dropdown-inside {
					padding: 30px;

					&.first {
					    padding: 30px;
					}
					&.paddingForDecorator {
						padding-bottom: 83px;
					}
					&.withoutVerticalPadding {
						padding-top: 0;
						padding-bottom: 0;
					}
				}
			}
		}

		.webauthn__dropdown {
			width: 100% !important;
			top: 0 !important;

			.webauthn__dropdown-wrapper {
				width: 100% !important;

				.webauthn__dropdown-inside {
					padding: 30px;

					&.first {
					    padding: 30px;
					}
					&.paddingForDecorator {
						padding-bottom: 83px;
					}
					&.withoutVerticalPadding {
						padding-top: 0;
						padding-bottom: 0;
					}
				}
			}
		}

		main {
			.gameList {
				&.gameList__livecasino {
					.liveCasino__wrapper {
						.liveCasino__item {
							.gameList__box {
								.gameList__boxImg {
									min-height: 140px;
								}
								.gameItem-button-wrapper {
									.gameItem-button {
										padding: 5px 20px !important;
									}
								}
								.gameList__title_wrapper {
									top: 38%;
									h2 {
										font-size: 20px;
										padding: 4px 10px;
										line-height: 25px !important;
									}
								}
								.gameList__boxLive {
									font-size: 14px !important;
									.extra-live-text {
										font-size: 14px !important;
									}
								}
							}
						}
					}
					.top-games,
					.third-games {
						.liveCasino__wrapper {
							.liveCasino__item {
								.gameList__box {
									.gameList__title_wrapper {
										h2 {
											font-size: 14px;
											padding: 2px 10px;
										}
									}
									.gameList__boxLive {
										display: none;
									}
								}
							}
						}
					}
					.top-games {
						.liveCasino__wrapper {
							&.top {
								.liveCasino__item {
									.gameList__box {
										.gameList__boxLive {
											display: none;
										}
										.gameList__vendor_wrapper {
											top: 15%;
											.gameList__vendor_bg {
												.gameList__vendor {
													width: 50%;
												}
											}
										}
										.gameItem-button-wrapper {
											bottom: 10%;
										}
									}
								}
							}
						}
					}
					.third-games {
						.liveCasino__wrapper {
							&.third {
								.liveCasino__item {
									.gameList__box {
										.gameList__vendor_wrapper {
											top: 15%;
											.gameList__vendor_bg {
												.gameList__vendor {
													width: 50%;
												}
											}
										}
										.gameItem-button-wrapper {
											bottom: 10%;
										}
									}
								}
							}
						}
					}
					.bottom-games {
						.liveCasino__wrapper {
							&:nth-child(n + 2) {
								margin-top: 2%;
							}
							&.fourth,
							&.bottom {
								width: 100% !important;
								margin-left: 0 !important;
							}
						}
					}
				}
			}
			.footerMenu {
				.footerMenu__item {
					.crestList {
						.crestList__item {
							padding: 0 10px !important;

							img {
								width: 60px !important;
							}
						}
					}
				}
			}

		}
		.sliderBlock {
			.sliderBlock {
				.main-slider {
			        .slider {
			            .slide {
			                div[class^="slide-item"] {
	                  			.button-wrapper {
							        top: -15px !important;
							    }
							}
						}
					}
				}
			}
		}
		.logosContainer {
			.logosSlider {
				.slider.platform-server {
					.slide {
					    .logosSlider__item {
    						flex: 1 0 100%;
					    }
					}
				}
			}
		}
	}
}

@media all and (max-width: 414px) {
	body {
		.sliderBlock {
		    .main-slider {
		        .slider {
		            max-height: 161px;
		        }
		    }
		}
	}
}

/* Mobiles (max 380px) ----------- */
@media all and (max-width: 380px) {
	body {

		a.button {
			padding: 8px 5px;
		}

		header {
			.headerContainer {
				&.headerContainerMobile {
					&::before {
						background-color: red;
						content: '';
						display: block;
						width: 100%;
						top: 50px;
						z-index: 2;
					}
					.headerMenu {
						.headerMenu__item {
							&.headerMenu__item--logo {
								padding-left:0;
								&::before {
									background-image: none !important;
								}

								background: {
									image: url('#{$images-path}/red-button-background.jpg');
									color: $primary-red;
								};

								.headerMenu__itemLogo {
									background: {
										image: url('#{$images-path}/red-button-background.jpg');
										color: $primary-red;
									};
								    max-width: 100%;
								}
							}
							&.headerMenu__item--responsible {
								display: none;
							}
						}
					}
				}
			}
		}

		main {
			.footerMenu {
				.footerMenu__item {
					.crestList {
						.crestList__item {
							padding: 0 8px !important;

							img {
								width: 50px !important;
							}
						}
					}
				}
			}
		}
	}

	.headerMenu__item--bonus {
		&::before {
			//background-image: none !important;
		}
	}
	.winnersContainer {
		.winnersSlider {
			.item {
				p.userName {
					font-size: 16px !important;
				}
				p.amount {
					font-size: 12px !important;
				}
			}
		}
	}
	.dont-break-out {
		white-space: normal;
		word-wrap: break-word;
	}
	
}


@media all and (max-height: 376px) {
	.stickyFooter {

		.stickyFooter__item {
			a {
				font-size: 14px !important;
			}
		}
	}
}

@media all and (max-width: 375px) {
	body {
		.sliderBlock {
		    .main-slider {
		        .slider {
		            max-height: 149px;
		        }
		    }
		}
	}
}

/* Older phones (max 359px) ----------- */
@media all and (max-width: 359px) {
	.winnersContainer {
		padding: 20px 5px !important;
	}
}



/* Styles for payment/vendors list on main page for desktop (only: owl-carousel) (min 981px) ----------- */
@media only screen and (min-width: 981px) {
  .licenseBar {
    .licenseBarContainer{
       > .licenseBar__element {
        .licenseBar__element-row {
          &.payments, &.vendors {
            owl-carousel {
              .owl-carousel {
                .owl-stage-outer {
                  .owl-stage {
                    width: auto !important;
                    transform: none !important;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    .owl-item {
                      width: auto !important;
                      flex: 1 0 14.285713%;
                      &.cloned {
                      	display: none !important;;
                      }
                      .licenseBar__element-image {
						padding: 2px 0;
                        img {
							display: inline;
							width: auto;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
	body {
		.paymentsSlider {
			.slider {
				.slide {
					width: auto !important;
	                transform: none !important;
	                display: flex;
	                flex-wrap: wrap;
	                align-items: center;
					>div {
						width: auto !important;
	                  	flex: 1 0 12.4%;
	                  	&.clone {
	                  		display: none;
	                  	}
					}
				}
			}
		}
	}
}

/* sliders server rendering styles */

.similarGamesSlider {
    .platform-server {
        .slide {
            @media all and (max-width: 1740px) {
                height: 135px;
            }
            @media all and (max-width: 1640px) {
                height: 120px;
            }
			@media all and (max-width: 1580px) {
                height: 125px;
                .item {
                	width: 11.1111%;
                }
            }
            @media all and (max-width: 1379px) {
                height: 120px;
                .item {
                	width: 12.5%;
                }
            }
            @media all and (max-width: 1279px) {
                height: 120px;
                .item {
                	width: 14.2858%;
                }
            }
			@media all and (max-width: 1219px) {
                height: 130px;
                .item {
                	width: 16.6666%;
                }
            }
            @media all and (max-width: 1119px) {
                height: 135px;
                .item {
                	width: 20%;
                }
            }
            @media all and (max-width: 1023px) {
                height: 155px;
                .item {
                	width: 25%;
                }
            }
            @media all and (max-width: 980px) {
                height: 190px;
            }
            @media all and (max-width: 870px) {
                height: 170px;
            }
            @media all and (max-width: 799px) {
                height: 200px;
                .item {
                	width: 33.3333%;
                }
            }
            @media all and (max-width: 768px) {
                height: 190px;
            }
            @media all and (max-width: 680px) {
                height: 170px;
            }
            @media all and (max-width: 639px) {
                height: 220px;
                .item {
                	width: 50%;
                }
            }
            @media all and (max-width: 600px) {
                height: 205px;
            }
            @media all and (max-width: 570px) {
                height: 65vw;
                .item {
                	width: 100%;
                }
            }
            @media all and (max-width: 539px) {
                height: 64vw;
            }
            @media all and (max-width: 420px) {
                height: 59vw;
            }
        }
    }
}

.winnersSlider {
    .slider {
    	@media all and (max-width: 1579px) {
    		&.platform-server {
	            height: 310px;
	            .slide {
	                .item {
	                    flex: 1 0 16.6666%;
	                }
	            }
	        }
	        .item {
				width: 16.6666%;
			}
        }
        @media all and (max-width: 1379px) {
        	&.platform-server {
	            height: 314px;
	            .slide {
	                .item {
	                    flex: 1 0 20%;
	                }
	            }
	        }
	        .item {
				width: 20%;
			}
        }
        @media all and (max-width: 1366px) {
			.item {
				width: 12.5%;
			}
        }
        @media all and (max-width: 1280px) {
        }
        @media all and (max-width: 1279px) {
        }
        @media all and (max-width: 1250px) {
        	&.platform-server {
	            height: 308px;
	        }
        }
        @media all and (max-width: 1219px) {
        	&.platform-server {
	            height: 335px;
	            .slide {
	                .item {
	                    flex: 1 0 25%;
	                }
	            }
	        }
	        .item {
                width: 25%;
            }
        }
        @media all and (max-width: 1160px) {
        	&.platform-server {
            	height: 320px;
        	}
        }
        @media all and (max-width: 1090px) {
        	&.platform-server {
            	height: 300px;
        	}
        }
        @media all and (max-width: 1075px) {
        	&.platform-server {
            	height: 300px;
        	}
        }
        @media all and (max-width: 980px) {
        	&.platform-server {
            	height: 350px;
        	}
        }
        @media all and (max-width: 921px) {
        	&.platform-server {
            height: 335px;
        	}
        }
        @media all and (max-width: 855px) {
        	&.platform-server {
            	height: 315px;
        	}
        }
        @media all and (max-width: 799px) {
        	&.platform-server {
	            height: 360px;
	            .slide {
	                .item {
	                    flex: 1 0 33.3333%;
	                }
	            }
	        }
	        .item {
                width: 33.3333%;
            }
        }
        @media all and (max-width: 745px) {
        	&.platform-server {
            	height: 345px;
        	}
        }
        @media all and (max-width: 690px) {
        	&.platform-server {
            	height: 330px;
        	}
        }
        @media all and (max-width: 650px) {
        	&.platform-server {
            	height: 310px;
        	}
        }
        @media all and (max-width: 600px) {
        	&.platform-server {
            	height: 310px;
        	}
        }
        @media all and (max-width: 561px) {
        	&.platform-server {
            	height: 360px;
        	}
        }
        @media all and (max-width: 539px) {
        	&.platform-server {
	            height: 348px;
	            .slide {
	                .item {
	                    flex: 1 0 50%;
	                    width: 50% !important;
	                }
	            }
	        }
	        .item {
                width: 50%;
            }
        }
        @media all and (max-width: 521px) {
        	&.platform-server {
            	height: 340px;
        	}
        }
        @media all and (max-width: 475px) {
        	&.platform-server {
            	height: 320px;
        	}
        }
        @media all and (max-width: 425px) {
        	&.platform-server {
            	height: 300px;
        	}
        }
        @media all and (max-width: 400px) {
        	&.platform-server {
            	height: 420px;
        	}
        }
        @media all and (max-width: 380px) {
        	&.platform-server {
            	height: 289px;
        	}
        }
    }
}
.mb35{
	margin-bottom: 35px;
}