// ? perfect-scrollbar v0.6.15
@import '../../../styles/variables';


.ps-container {
  -ms-touch-action: auto;
  touch-action: auto;
  overflow: hidden !important;
  -ms-overflow-style: none;
  &.ps-active-x > .ps-scrollbar-x-rail, &.ps-active-y > .ps-scrollbar-y-rail {
    display: block;
    background-color: transparent;
  }
  &.ps-in-scrolling {
    &.ps-x > .ps-scrollbar-x-rail {
      background-color: $primary-white;
      opacity: .9;
      > .ps-scrollbar-x {
        background-color: $hints-color;
        height: 11px;
      }
    }
    &.ps-y > .ps-scrollbar-y-rail {
      background-color: $primary-white;
      opacity: .9;
      > .ps-scrollbar-y {
        background-color: $hints-color;
        width: 11px;
      }
    }
  }
  > {
    .ps-scrollbar-x-rail {
      display: none;
      position: absolute;
      opacity: 0;
      -webkit-transition: background-color .2s linear, opacity .2s linear;
      -o-transition: background-color .2s linear, opacity .2s linear;
      -moz-transition: background-color .2s linear, opacity .2s linear;
      transition: background-color .2s linear, opacity .2s linear;
      bottom: 0px;
      height: 15px;
      > .ps-scrollbar-x {
        position: absolute;
        background-color: $primary-grey;
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        border-radius: 6px;
        -webkit-transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, -webkit-border-radius .2s ease-in-out;
        transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, -webkit-border-radius .2s ease-in-out;
        -o-transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out;
        -moz-transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out, -moz-border-radius .2s ease-in-out;
        transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out;
        transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out, -webkit-border-radius .2s ease-in-out, -moz-border-radius .2s ease-in-out;
        bottom: 2px;
        height: 6px;
      }
      &:hover > .ps-scrollbar-x, &:active > .ps-scrollbar-x {
        height: 11px;
      }
    }
    .ps-scrollbar-y-rail {
      display: none;
      position: absolute;
      opacity: 0;
      -webkit-transition: background-color .2s linear, opacity .2s linear;
      -o-transition: background-color .2s linear, opacity .2s linear;
      -moz-transition: background-color .2s linear, opacity .2s linear;
      transition: background-color .2s linear, opacity .2s linear;
      right: 0;
      width: 15px;
      > .ps-scrollbar-y {
        position: absolute;
        background-color: $primary-grey;
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        border-radius: 6px;
        -webkit-transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, -webkit-border-radius .2s ease-in-out;
        transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, -webkit-border-radius .2s ease-in-out;
        -o-transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out;
        -moz-transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out, -moz-border-radius .2s ease-in-out;
        transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out;
        transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out, -webkit-border-radius .2s ease-in-out, -moz-border-radius .2s ease-in-out;
        right: 2px;
        width: 6px;
      }
      &:hover > .ps-scrollbar-y, &:active > .ps-scrollbar-y {
        width: 11px;
      }
    }
  }
  &:hover {
    &.ps-in-scrolling {
      &.ps-x > .ps-scrollbar-x-rail {
        background-color: $primary-white;
        opacity: .9;
        > .ps-scrollbar-x {
          background-color: $hints-color;
          height: 11px;
        }
      }
      &.ps-y > .ps-scrollbar-y-rail {
        background-color: $primary-white;
        opacity: .9;
        > .ps-scrollbar-y {
          background-color: $hints-color;
          width: 11px;
        }
      }
    }
    > {
      .ps-scrollbar-x-rail, .ps-scrollbar-y-rail {
        opacity: .6;
      }
      .ps-scrollbar-x-rail:hover {
        background-color: $primary-white;
        opacity: .9;
        > .ps-scrollbar-x {
          background-color: $hints-color;
        }
      }
      .ps-scrollbar-y-rail:hover {
        background-color: $primary-white;
        opacity: .9;
        > .ps-scrollbar-y {
          background-color: $hints-color;
        }
      }
    }
  }
}

@supports (-ms-overflow-style: none) {
  .ps-container {
    overflow: auto !important;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps-container {
    overflow: auto !important;
  }
}

@import './_perfectScrollbar-override.component';