@import '../../_variables';
@import '../../_helpers';

@keyframes new-tab-animation {
	from {
		transform: translateX(50px)
	}
	to {
		transform: translateX(0)
	}
}

.latestOpenedGames {
	@extend .unselectable;
	transition: right 0.25s ease-in-out;
	position: fixed;
	width: 150px;
	height: auto;
	max-height: calc(100vh - 83px);
	overflow: hidden;
	background: {
		color: $gray-color-2;
		image: url('#{$images-path}/background.jpg');
	};
	left: auto;
	right: -103px;
	top: 155px;
	z-index: 271;
	box-shadow: 0 0 40px rgba(0, 0, 0, 0.2);
	margin-top: 0;
	padding: 4px 0;

	.latestOpenedGames-item {
		text-decoration: none;
		overflow: visible !important;
		width: inherit;
		opacity: 1;
		white-space: nowrap;
		display: block;
		color: $primary-white;
		font-size: 9px;
		cursor: pointer;
		word-wrap: break-word;
		padding: 12px 0;
		min-height: 20px;
		right: 0;
		position: relative;
		text-transform: capitalize;
		max-height: 86px !important;
		font-weight: 500;
		padding: 4px 8px;
		font-size: 11px;

		.latestOpenedGames-itemImage {
			width: 31px;
			height: 31px;
			position: absolute;
			background: {
				size: auto 100%;
			};
		}

		.latestOpenedGames-itemName {
			@extend .truncate-text;
			margin-left: 39px;
			line-height: 31px;
		    max-width: 122px;
		    display: block;
		    font-size: 11px;
		}
	}

	&:hover {
		right: 0;
	}
}

/* Desktops (max 1440px) ----------- */
@media only screen and (max-width : 1440px) {
	.latestOpenedGames {
		right: -113px;
		.latestOpenedGames-item {
			.latestOpenedGames-itemImage,
			.latestOpenedGames-itemName {
				height: 21px;
				line-height: 21px;
			}
			
			.latestOpenedGames-itemImage {
				width: 21px;
			}

			.latestOpenedGames-itemName {
				margin-left: 29px;
			}
		}
	}
}

/* Desktops (max 980px) ----------- */
@media only screen and (max-width : 980px) {
	.latestOpenedGames {
		display: none;
	}
}