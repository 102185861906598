//@import '../../ngx-bar/br-fontawesome-o-theme.css';
@import '../../../styles/variables';


/* br-fontawesome-o-theme.css */
.br-fontawesome-o .br-unit{align-items:center}.br-fontawesome-o .br-unit{font:normal normal normal 20px/1 FontAwesome;margin-right:2px}.br-fontawesome-o .br-unit:after{content:'\f006';color:#d2d2d2}.br-fontawesome-o .br-selected:after{content:'\f005';color:#50E3C2}.br-fontawesome-o .br-active:after{content:'\f005';color:#50E3C2}.br-fontawesome-o .br-fraction:after{content:'\f123';color:#50E3C2}

/* END br-fontawesome-o-theme.css*/

.br {
    margin: 0 !important;
    
    &.br-fontawesome-o {
        .br-unit {
            transition: color .3s linear;
            color: $primary-white;

            &::after {
                transition: inherit;
            }

            &.br-selected,
            &.br-fraction {
                &::after {
                    color: $primary-white !important;
                }
            }

            &.br-active {
                &::after {
                    color: $primary-green !important;
                }
            }
        }
    }
}

/* Desktops (max 520px) ----------- */
@media only screen and (max-width : 520px) {
    .br-units {
        align-items: center;
        justify-content: center;
    }
}