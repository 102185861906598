// ? Colors
// * Viewport
$viewport-padding: 20px;
$background-color: #f1f1f1;
$page-background-color: #d6d6d6;

// * Primary Site Colors
$primary-white: #ffffff;
$primary-black: #1a1a1a;
$primary-pure-black: #000000;
$primary-grey-lighter: #f2f2f2;
$primary-grey: #c4c4c4;
$primary-grey-dark: #bdbdbd;
$primary-grey-more-dark: #999999;
$primary-blue: #3d5a94;
$primary-blue-dark: #274069;
$primary-blue-other: #0d84d8;
$primary-red: #d80c23;
$primary-green: #078670;

// * Headers
$header-second-accent: #098470;
$header-background-gray: #353535;
$header-background-gray-alt: #3d3d3d;
$header-background-after: #676767;

// * Labels
$page-background-color: #272727;
$label-color-main: #b3b3b3;

// * Anchors
$anchor-color: #c7c7c7;

// * Checkboxes
$check-box-background-color: #0e0e0e;

// * Select Boxes
$select-border-color: #4c4c4c;
$select-border-color-focus: #838383;
$select-background-color: #0d0d0d;

// * Misc
$hints-color: #999;
$selection-color: #2ecaa2;
$avatar-before-color: #e6e6e6;
$multi-action-container: #ffc107;
$paragraph-main-color: #a9a9a9;
$alert-warning-color: #ffdb5b;
$alert-info-color: #03A9F4;

// * Buttons
$button-main-color: #565656;
$button-background-orange: #f57e2e;
$button-background-orange-alt: #ec4a1d;
$button-main-border: #3a3a3a;
$button-background-blue: #37548e;

// * Footer
$footer-background-color: #262626;

// * Grays
$gray-color: #868686;
$gray-color-1: #6a6a6a;
$gray-color-2: #4a4a4a;
$gray-color-3: #333;
$gray-color-4: #1d1d1d;

// * Progress Bar
$progress-bar-background: #bcbcbc;
$progress-bar-20-percent: #cb0b21;
$progress-bar-40-percent: #d8520c;
$progress-bar-40-percent: #f29211;
$progress-bar-80-percent: #b2b00f;

// * GDPR Cookie Bar
$cookie-bar-background-color: #373737;

// * Live Casino
$live-indicator-color: #78bd1f;

// * Vendors
$vendor-container-border: #474747;

// * Fonts
$base-font-family:  'Open Sans', serif;
$base-font-family-alt:  exo2-bold,'Open Sans', serif;
$base-font-family-slider:  lemon_milk_light, 'Open Sans', serif;
$base-font-family-font-awesome:  FontAwesome;
$base-font-family-wicked-mouse:  wicked_mouseregular;

// * Headers
$header-element-margin-top: 16px;
$header-min-height: 70px;
$header-item-padding: 34px;
$header-default-font-size: 13px;
$header-search-height: 70px;
$header-counter-size: 14px;

$baseFontSize: 14px;

// * Grids
$left-column-min-width: 80px;
$left-column-width: 260px;
$column-padding-width: 0;

// * Game Items
$game-item-padding: 10px;

// * Main Menu
$main-menu-separator-padding: 15px;

// * Fixed Buttons
$fixed-buttons-top-position: 222px;

// ? Paths
$images-path: '/assets/images';
$icons-path: '/assets/icons';
$font-path: '/assets/fonts';

// * Modal
$min-modal-width: 300px;
$min-modal-height: 200px;
$min-z-index: 100;
$modal-padding: 0;

$modal-title-height: 24px;
$modal-title-font-color: $primary-white;
