@import './variables';

// * Button Mixins
@mixin add-to-favorite-btn {
  transition: all 0.25s ease-in-out;
  position: static;
  display: inline-block;
  text-align: center;
  background-color: $primary-white;
  border-radius: 90%;
    transform: scale(1);
    opacity: 1;
  z-index: 2;
  cursor: pointer;
  min-width: 38px;
  &::before {
      transition: inherit;
      position: relative;
      width: 38px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      content: "\f08a";
      font-family: $base-font-family-font-awesome;
      color: $primary-red;
      left: 1px;
      font-size: 19px;
      top: 2px;
  }
  &.active,
  &:hover {
      background-color: $primary-red;
      &::before {
          color: $primary-white;
      }
  }
  &.active {
      transform: scale(1);
      opacity: 1;
      &::before {
          content: "\f004";
      }
  }
}

// * Game Ribbon Mixins
@mixin game-ribbon-background-color ($color) {
    background: linear-gradient(lighten($color: $color, $amount: 10%) 0%, 
    darken($color: $color, $amount: 10%) 100%);
}

@mixin game-ribbon-border ($color, $rightBorder: true) {
    border-top: 3px solid darken($color: $color, $amount: 10%);
    @if $rightBorder {
        border-right: 3px solid darken($color: $color, $amount: 10%);
    } @else {
        border-left: 3px solid darken($color: $color, $amount: 10%);
    }
}

@mixin game-ribbon-default ($color: white) {
    @include game-ribbon-background-color($color);
    .ribbon-label-helper1 {
        @include game-ribbon-border ($color, false);
    }
    .ribbon-label-helper2 {
        @include game-ribbon-border ($color);
    }
}

@mixin default-button-hover-effect ($color: white, $darken: true) {
    background: $color;
    &:hover {
      @if $darken {
        background: darken($color: $color, $amount: 10%);
      } @else {
        background: lighten($color: $color, $amount: 10%);
      }
    }
}

// * SnackBar Types
@mixin snackbar-type($color, $linkColor: $button-background-orange) {
    background-color: $color;
    color: $primary-grey-lighter;
    display: flex;
    align-content: center;
    align-items: center;
    font-size: $header-default-font-size + 1;
    justify-content: space-between;
    p > a {
        text-decoration: none;
        color: $linkColor;
        transition: .5s ease-in-out;
        &:hover {
            cursor: pointer;
            color: lighten($color: $linkColor, $amount: 20%);
        }
    }
    .mat-button {
        @include rounded-corners-all(0px);
        @include shadow-component-all();
        float: right;
        color: $primary-grey-lighter;
        background-color: darken($color: $color, $amount: 10%);
        margin: auto 10px;
        width: 25%;
    }
}

// * Add Shadow to the Component
@mixin shadow-component-all() {
    -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.25) !important;
    -moz-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.25) !important;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.25) !important;
}

// * Makes the Component with Sharp Edges
@mixin rounded-corners-all($size: 10px) {
    border-radius: $size !important;
    -webkit-border-radius: $size !important;
    -moz-border-radius: $size !important;
}

@mixin mat-raised-button($backgroundColor, $color) {
    @include shadow-component-all();
    @include rounded-corners-all(4px);
    background-color: $backgroundColor;
    color: $color;
    transition: .5s ease-in-out;
    &:hover {
        background-color: darken($color: $backgroundColor, $amount: 10%);
    }
}