@import '../../_variables';
@import '../../_helpers';

jaspero-alert {
    &.info {
        .jaspero__alert_wrapper{
            .jaspero__alert_wrapper_symbol {
                svg {
                    padding: 1rem;
                    margin: 0;
                    height: 83px;
                    path {
                        transform: scale(0.86);
                    }
                }
            }
        }
    }
    .jaspero__alert_overlay {
        z-index: 1207 !important;
    }
    .jaspero__alert_wrapper{
        background: $primary-black !important;
        border-radius: 0 !important;
        padding-bottom: 75px;
        width: auto !important;
        max-width: 400px;
        min-width: 300px;
        z-index: 1208 !important;
        @media only screen and (max-width : 400px) {
            left: 0 !important;
        }
        .jaspero__alert_wrapper_symbol {
            padding: 40px 20px 0;
            text-align: center;
            svg {
                padding: 0;
                margin-left: 35px;
            }
        }
        .jaspero__alert_wrapper_message {
            font-size: 20px !important;
            text-transform: none !important;
            font-weight: 700 !important;
            padding: 0 30px 0 !important;
            margin-bottom: 10px !important;
            line-height: 30px !important;
        }
        .jaspero__alert_wrapper_short {
            font-size: 14px !important;
            padding: 0 30px 22px !important;
            font-weight: 400;
        }
        .jaspero__alert_wrapper_close {
            bottom: 24px;
            top: auto;
            width: calc(100% - 44px);
            left: 22px;
            border-radius: 0;
            background: rgba(141, 150, 165, 0.2);
            box-shadow: 0 3px 0 rgba(0,0,0,.6);
            height: 45px;
            &:hover {
                transform: none;
            }
            &:after {
                content: 'OK';
                text-align: center;
                font-weight: 600 !important;
                line-height: 45px;
                text-transform: uppercase;
            }
            svg {
                display: none;
            }
        }
    }
}