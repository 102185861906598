@import '_grids-core';

// @TODO: Remove comments
.pure-g.padding {
	/* position: relative;
	left: -10px;
	right: -10px; */

	[class*="pure-u-"] {
		box-sizing: border-box;
		padding: 10px;
	}
}

[class*="pure-u-"] {
	letter-spacing: normal;
}


.pure-u-2-4, {
	width: 50%
}