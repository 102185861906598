@import '../../../styles/variables';

$static-pages-right-column-width: 300px;
$static-pages-menu-horizontal-padding: 30px;

.staticPagesContainer {
	width: 100%;
	display: flex;

	.staticPagesContainer__inside {
		width: 100%;
    	padding-top: 40px;
	}

	.staticPagesContainer__leftColumn {
		display: inline-block;
		width: calc(100% - #{$static-pages-right-column-width});
		padding: {
			bottom: 60px;
			right: 40px;
		};
	}

	.staticPagesContainer__rightColumn {
		display: inline-block;
		width: $static-pages-right-column-width;
    margin-top: 40px;

    &--withoutMargin {
      margin-top: 0;
    }
	}
}

.staticPagesContainer__leftColumn-item {
	margin-bottom: -5px;
	.title {
		margin-bottom: 40px !important;
	}
	.paragraph {
		font-size: 14px;
		line-height: 23px;
		color: $paragraph-main-color;
	}

	a {
		color: $primary-red;
		text-decoration: none;
		&:hover {
			color: $primary-white;
		}
	}
}

/* Desktops (max 1120px) ----------- */
@media only screen and (max-width : 1120px) {
    $static-pages-right-column-width: 270px;
    .staticPagesContainer {
        .staticPagesContainer__leftColumn {
            width: calc(100% - #{$static-pages-right-column-width});
            padding-right: 25px;
            .staticPagesContainer__leftColumn-item {
                .black-container {
                    padding: 15px 15px 30px 15px;
                }
            }
        }
        .staticPagesContainer__rightColumn{
            width: $static-pages-right-column-width;
        }
    }
}

/* Desktops (max 1040px) ----------- */
@media only screen and (max-width : 1040px) {
    .staticPagesContainer {
        .staticPagesContainer__leftColumn {
            padding-right: 10px;
            .staticPagesContainer__leftColumn-item {
                .black-container {
                    padding: 15px 3px 30px 3px;
                }
            }
        }
    }
}

/* Desktops (max 980px) ----------- */
@media only screen and (max-width : 980px) {
	.staticPagesContainer {
		width: 100%;
		display: block;

		.staticPagesContainer__leftColumn,
		.staticPagesContainer__rightColumn {
			display: block;
			width: 100%;
			padding: 0;
			margin-bottom: 20px;
		}
	}
}

.staticPages {
	list-style: none;
	background-color: $footer-background-color;
	padding: 15px 0 15px 0;
	margin: 0;
	position: relative;
	box-shadow: 0 3px 0 rgba(0, 0, 0, 0.5);

	& > .staticPages__item {
		&::after {
			display: block;
			height: 1px;
			width: calc(100% - (2 * #{$static-pages-menu-horizontal-padding}));
			content: '';
			background: {
				image: url('#{$images-path}/horizontal-dot-separator.png');
				repeat: repeat-x;
			};
			opacity: 0.2;
			margin-left: $static-pages-menu-horizontal-padding;
		}

		.staticPages__item-anchor {
			transition: all 0.25s ease-in-out;
			color: $primary-white;
			font-weight: 700;
			text-decoration: none;
			display: block;
			padding: {
				top: 15px;
				right: $static-pages-menu-horizontal-padding;
				bottom: 15px;
				left: $static-pages-menu-horizontal-padding;
			};
			font-size: 14px;
			line-height: 20px;
			position: relative;
			border-left: 4px solid transparent; 

			&::after {
				transition: inherit;
				content: "\f105";
				font-family: "FontAwesome";
				position: absolute;
				right: $static-pages-menu-horizontal-padding;
				font: {
					size: 20px;
					weight: lighter;
				};
			}

			&:hover,
			&.staticPages__item-anchor--active {
				background-color: $primary-black;
				border-color: $primary-red;
			}

			&:hover {
				&::after {
					right: calc(#{$static-pages-menu-horizontal-padding} - 5px);
				}
			}
		}

		&:last-child {
			&::after {
				display: none;
			}
		}
	}

	&:hover {
		& > .staticPages__item {
			.staticPages__item-anchor {
				&.staticPages__item-anchor--active {
					background-color: transparent;
					border-color: transparent;

					&:hover {
						background-color: $primary-black;
						border-color: $primary-red;
					}
				}
			}
		}
	}
}
