@import '../../_variables';
@import '../../_helpers';

.jaspero__overlay {
	z-index: 110;
	background-color: rgba(0, 0, 0, 0.85) !important;
}

.jaspero__dialog {
	z-index: 111;
	box-shadow: 0 0 30px rgba(0, 0, 0, 0.6) !important;
	background: {
		color: $primary-black !important;
		image: url('#{$images-path}/background.jpg') !important;
	};

	button {
		&.default {
			@extend .button, .button--grey;
			font-weight: 600 !important;
		}

		&.primary {
			@extend .button, .button--green;
			font-weight: 700 !important;
		}
	}

	.jaspero__dialog-title,
	.jaspero__dialog-content {
		letter-spacing: normal !important;
		color: $primary-white !important;
	}

	.jaspero__dialog-title {
		font-size: 20px !important;
		text-transform: none !important;
		font-weight: 700 !important;
		padding: 30px 30px 0 !important;
		margin-bottom: 10px !important;
		line-height: 30px !important;
	}

	.jaspero__dialog-content {
		font-size: 14px !important;
		padding: 0 30px 22px !important;
	}

	.jaspero__dialog-actions {
		padding: 8px 22px 22px 24px !important;
	}
}